<template>
  <mdui-card class="user-container">
    <mdui-avatar :src="userInfo.avtr_url || 'https://static.vdsentnet.com/userstatic/default_avatar.png'" class="shadowed-img useravtr" />
    <h1>{{ userInfo.nickname || userInfo.username }}</h1>
    <p class="email-addr">{{ userInfo.username || '无绑定电子邮件' }}</p>
  </mdui-card>
  <mdui-card class="actions-container">
    <p>你现在可以对你的账户进行：</p>
    <mdui-button @click="$router.push('/my/editname')">
      修改用户名（实时聊天）
      <!-- eslint-disable-next-line vue/no-deprecated-slot-attribute -->
      <mdui-icon slot="icon" name="edit"></mdui-icon>
    </mdui-button>

    <mdui-button style="margin-left: 10px;" @click="$router.push('/my/movetoven')">
      迁移到VEN账户
      <!-- eslint-disable-next-line vue/no-deprecated-slot-attribute -->
      <mdui-icon slot="icon" name="edit"></mdui-icon>
    </mdui-button>

    <mdui-button style="margin-left: 10px;" @click="$router.push('/my/logout')">
      退出登录
      <!-- eslint-disable-next-line vue/no-deprecated-slot-attribute -->
      <mdui-icon slot="icon" name="logout"></mdui-icon>
    </mdui-button>
  </mdui-card>
  <mdui-card class="actions-container">
    <p>快速入口：</p>
    <mdui-button href="https://ls.vds.pub/hello">申请直播间
      <!-- eslint-disable-next-line vue/no-deprecated-slot-attribute -->
      <mdui-icon slot="icon" name="album"></mdui-icon>
    </mdui-button>
    <mdui-button href="mailto:livestream@vds.pub" style="margin-left: 10px;">请求支持
      <!-- eslint-disable-next-line vue/no-deprecated-slot-attribute -->
      <mdui-icon slot="icon" name="support"></mdui-icon>
    </mdui-button>
  </mdui-card>
  <mdui-card class="actions-container">
    <p>应用信息：</p>
    <mdui-button @click="$router.push('/about')">关于
      <!-- eslint-disable-next-line vue/no-deprecated-slot-attribute -->
      <mdui-icon slot="icon" name="info"></mdui-icon>
    </mdui-button>
    <p style="color: gray;">LSWebApp Version:1.7.2 Build:2024-11-09 21:51:43 Env:production</p>
  </mdui-card>
</template>

<script>
import axios from 'axios';
import { ref, onMounted } from 'vue';

export default {
  name: 'UserManagePage',
  setup() {
    const userInfo = ref({
      username: '',
      nickname: '',
      phonenum: '',
      avtr_url: '',
    });

    const fetchUserInfo = async () => {
      try {
        const token = localStorage.getItem('token');
        const response = await axios.get('https://1.13.182.201:5688/user/info', {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        if (response.data.success) {
          userInfo.value = response.data.user;
        } else {
          alert('无法获取用户信息');
        }
      } catch (error) {
        console.error('获取用户信息时出错:', error);
        alert('获取用户信息失败');
      }
    };

    onMounted(() => {
      fetchUserInfo();
    });

    return {
      userInfo,
    };
  },
};
</script>

<style scoped>
.user-container {
  width: 100%;
  margin-top: 20px;
  padding: 15px;
}

.actions-container {
  width: 100%;
  padding: 15px;
}

.status-banner {
  margin-top: 20px;
}

.status-card {
  width: 100%;
  text-align: center;
  padding: 10px 0;
  border-radius: 5px;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.email-addr {
  color: gray;
  margin-top: -15px;
}

.shadowed-img img {
  filter: drop-shadow(0 4px 8px rgba(0, 0, 0, 0.4));
}

.useravtr {
  width: 200px;
  height: 200px;
}

.yellow {
  background-color: #e6a900;
  color: #ffffff;
}
</style>