<template>
  <div :class="{'dark-mode': isDarkMode}" class="app-container">
    <!-- 顶部加载进度条 -->
    <mdui-linear-progress
      v-if="loading"
      indeterminate
      class="mdui-progress"
    ></mdui-linear-progress>

    <!-- 顶部导航栏（当没有接收到 chatwindowOpened 事件时显示）-->
    <NavigationBar v-if="!ishandlechatwindowOpened" />

    <!-- 主体部分：左侧导航栏和内容区域 -->
    <div class="main-container">
      <!-- 左侧导航栏（当没有接收到 chatwindowOpened 事件时显示）-->
      <NavigationRail v-if="!ishandlechatwindowOpened" />

      <!-- 内容区域 -->
      <div class="content-container">
        <router-view @route-change="applyImageEffects" />
      </div>
    </div>
  </div>
</template>

<script>
import NavigationBar from './components/NavigationBar.vue';
import NavigationRail from './components/NavigationRail.vue';
import 'mdui/mdui.css';

export default {
  name: 'App',
  components: {
    NavigationBar,
    NavigationRail,
  },
  data() {
    return {
      loading: false,
      isDarkMode: false, // 深色模式的状态
      ishandlechatwindowOpened: false, // chatwindowOpened 事件状态
    };
  },
  created() {
    // 监听加载状态
    this.$emitter.on('loading', (status) => {
      this.loading = status;
    });

    // 监听 chatwindowOpened 事件
    window.addEventListener('chatwindowOpened', this.handlechatwindowOpened);
  },
  beforeUnmount() {
    // 移除事件监听器
    window.removeEventListener('chatwindowOpened', this.handlechatwindowOpened);
  },
  mounted() {
    this.applyImageEffects();
  },
  methods: {
    // 处理 chatwindowOpened 事件
    handlechatwindowOpened() {
      this.ishandlechatwindowOpened = true;
    },
    applyImageEffects() {
      const darkModeSetting = localStorage.getItem('darkMode');
      this.isDarkMode = darkModeSetting === 'on';

      // 无论是否为深色模式，均执行阴影效果
      setTimeout(() => {
        this.addShadowToImages();
      }, 20); // 延迟 20ms

      // 如果是淡色模式，才执行反色效果
      if (!this.isDarkMode) {
        setTimeout(() => {
          this.invertImagesInContainers();
        }, 20); // 延迟 20ms
      }
    },
    addShadowToImages() {
      const shadowContainers = document.querySelectorAll('.shadow-container');
      shadowContainers.forEach((container) => {
        const images = container.querySelectorAll('img');
        images.forEach((img) => {
          img.style.filter = 'drop-shadow(0 4px 8px rgba(0, 0, 0, 0.2))';
        });
      });
    },
    invertImagesInContainers() {
      const invertContainers = document.querySelectorAll('.invert-container');
      invertContainers.forEach((container) => {
        const images = container.querySelectorAll('img');
        images.forEach((img) => {
          img.style.filter = 'invert(1)';
        });
      });
    },
  },
};
</script>

<style>
/* 总容器，包含顶部导航栏和主体部分 */
.app-container {
  display: flex;
  flex-direction: column;
  height: 100vh; /* 让容器填满整个视窗高度 */
}

/* 深色模式 */
.dark-mode img {
  filter: none; /* 深色模式下图片不需要阴影或反色 */
}

/* 顶部进度条样式 */
.mdui-progress {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1000;
}

/* 主体部分，包含左侧导航栏和内容区域 */
.main-container {
  display: flex;
  flex: 1; /* 主体部分填满剩余空间 */
  margin-top: 50px; /* 留出顶部导航栏的空间 */
}

/* 内容区域 */
.content-container {
  flex: 1; /* 填满剩余空间 */
  padding: 5px; /* 添加一些内边距 */
  overflow-y: auto; /* 使内容可以滚动 */
}

body {
  transition: all 0.3s ease;

}
</style>