<template>
  <router-link :to="`/article/${article.id}`" class="no-decoration">
    <mdui-card class="card" style="width: 300px; height: 300px;">
      <div class="mdui-card-media position-relative">
        <img :src="article.image_url" :alt="`文章图片: ${article.title}`" class="card-image" />
        <div class="overlay">
          <div class="text">
            <div class="badge">{{ article.badge }}</div>
            <div class="title">{{ article.title }}</div>
            <div class="description">{{ article.description }}</div>
            <div style="margin-bottom: 5px;"></div>
          </div>
        </div>
      </div>
    </mdui-card>
  </router-link>
</template>

<script>
export default {
  name: 'SpotlightCard',
  props: {
    article: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style scoped>
.card {
  border-radius: 8px; /* 可选：圆角 */
  overflow: hidden; /* 隐藏溢出的内容 */
}

.card-image {
  width: 100%; /* 确保图片宽度为100% */
  height: auto; /* 让高度自适应 */
  object-fit: cover; /* 适应图片填充 */
}

.position-relative {
  position: relative; /* 为了可以绝对定位覆盖层 */
}

.overlay {
  position: absolute; /* 绝对定位 */
  bottom: 0; /* 固定在底部 */
  left: 0;
  right: 0;
  padding: 10px; /* 内边距 */
}

.text {
  color: white; /* 文字颜色为白色 */
  text-align: left; /* 左对齐 */
}

.title {
  font-size: 25px; /* 标题大小 */
  margin: 0; /* 移除默认边距 */
  font-weight: 500; /* 标题加粗 */
}

.description {
  font-size: 12px; /* 描述大小 */
  margin-top: 4px; /* 描述与标题之间的间距 */
}
</style>
