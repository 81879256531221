<template>
  <div class="ma-body">
    <!-- 页面内容 -->
    <div class="content">
      <!-- 加载指示器（可选） -->
      <div v-if="loading" class="loading-indicator">
        <mdui-circular-progress></mdui-circular-progress>
        <p>加载中...</p>
      </div>

      <!-- 错误信息 -->
      <div v-if="error" class="error-message">
        <p>{{ error }}</p>
      </div>

      <!-- 直播间标签和面板 -->
      <mdui-tabs ref="tabs" v-if="!loading && !error && liveRooms.length > 0" :value="currentTab"
        class="example-placement">
        <!-- 标签项 -->
        <template v-for="room in liveRooms" :key="'tab-' + room.room_id">
          <mdui-tab :value="'tab-' + room.room_id" :class="{ 'mdui-tab-active': currentTab === 'tab-' + room.room_id }"
            @click="currentTab = 'tab-' + room.room_id">
            {{ room.room_id }}
          </mdui-tab>
        </template>

        <!-- 面板项 -->
        <template v-for="room in liveRooms" :key="'panel-' + room.room_id">
          <!-- eslint-disable-next-line vue/no-deprecated-slot-attribute -->
          <mdui-tab-panel slot="panel" :value="'tab-' + room.room_id"
            :class="{ 'live-panel': true, 'mdui-panel-active': currentTab === 'tab-' + room.room_id }">
            <!-- 直播间信息卡片 -->
            <mdui-card style="width: 100%; margin-top: 20px;">
              <div class="liveroom-card" style="margin-left: 20px">
                <div style="display: flex; align-items: center;">
                  <img :src="room.image_url" alt="直播间图片" style="width: 130px; height: auto; margin-top: 20px;" />
                  <div style="margin-left: 15px; margin-top: 20px;">
                    <h2>{{ room.room_id }}</h2>
                    <h4 style="margin-top: -20px;">
                      直播间URL:
                      <a :href="'https://ls.vds.pub/room.php?id=' + room.room_id" target="_blank">
                        vds.pub/live/room.php?id={{ room.room_id }}
                      </a>
                    </h4>
                  </div>
                </div>
                <p style="color: gray">图片URL：{{ room.image_url }}</p>
                <p style="color: gray; margin-bottom: 20px;">
                  播放URL：
                  <a :href="room.player_url" target="_blank">{{ room.player_url }}</a>
                </p>
              </div>
            </mdui-card>

            <!-- 开播设置 -->
            <h1>开播设置</h1>
            <mdui-button :loading="room.loading" :id="'streamButton-' + room.room_id"
              @click="toggleLive(room.room_id, room.status)" :class="{
                'mdui-btn-raised': true,
                'mdui-color-theme-accent': room.status === 'on',
                'mdui-color-theme': room.status === 'off',
              }">
              {{ room.status === 'on' ? '结束直播' : '开始直播' }}
            </mdui-button>

            <!-- 隐藏内容 -->
              <div v-if="room.status === 'on'" :key="'hiddenContent-' + room.room_id" class="live-hidden-content">
                <!-- 直播状态
                <div :id="'liveStatus-' + room.room_id" style="width: 100%; margin-top: 15px;">
                  <mdui-card style="width: 100%; margin-top: 15px;">
                    <div style="display: flex; align-items: center;">
                      <template v-if="room.streamState === 'active'">
                        <mdui-icon style="margin-left: 15px; color: green;">&#xe5ca;</mdui-icon>
                        <h3 style="margin-top: 25px; margin-left: 10px;">
                          连接良好，你正在直播。
                        </h3>
                      </template>
<template v-else>
                        <mdui-circular-progress style="margin-top: 5px; margin-left: 15px"></mdui-circular-progress>
                        <h3 style="margin-top: 25px; margin-left: 10px;">
                          VDS没有收到此直播的数据，确保你正在推流，且为其使用了正确的直播密钥。
                        </h3>
                      </template>
</div>
</mdui-card>
</div> -->

                <!-- 直播服务器信息 -->
                <div style="display: flex; align-items: center; margin-top: 15px;">
                  <mdui-text-field :id="'Server-' + room.room_id" variant="outlined" readonly label="直播服务器地址"
                    :value="room.stream_url"></mdui-text-field>
                  <mdui-button variant="elevated" style="margin-bottom: 20px; margin-top: 10px;"
                    @click="copyText('Server-' + room.room_id)">
                    复制
                  </mdui-button>
                </div>

                <!-- 直播密钥 -->
                <div style="display: flex; align-items: center;">
                  <mdui-text-field :id="'Secret-' + room.room_id" variant="outlined" readonly helper="将此信息粘贴到你的编码器中。"
                    label="直播密钥" :value="room.stream_key"></mdui-text-field>
                  <mdui-button variant="elevated" style="margin-bottom: 20px; margin-top: 10px;"
                    @click="copyText('Secret-' + room.room_id)">
                    复制
                  </mdui-button>
                </div>
                <mdui-button variant="elevated" style="margin-bottom: 20px; margin-top: 10px;"
                  @click="openChatWindow(room.room_id)">
                  弹出实时聊天窗口(测试版)
                </mdui-button>

                <!-- <mdui-button variant="elevated" style="margin-bottom: 20px; margin-top: 10px; margin-left: 10px;"
                  @click="copyText('Secret-' + room.room_id)">
                  挑选云插件
                </mdui-button> -->
              </div>

            <!-- 下方的元素 -->
          <p style="color: gray">
              开始直播即代表同意VDSLiveStream的条款和条件，请勿直播违规信息。
            </p>

               <!-- 添加发送弹窗功能 -->
               <h1>发送弹窗</h1>
               <p style="color: gray">
             该功能在1.7版本中目前存在问题，需要等待架构更新，现在发送可能会出现重复显示弹窗的情况。
            </p>
               <mdui-text-field rows="4" name="message" id="message" label="弹窗内容"></mdui-text-field>
                <br />
                <mdui-button
                  style="margin-top: 15px;"
                  @click="sendInteraction(room.room_id)"
                  :disabled="room.sendingInteraction"
                  :loading="room.sendingInteraction"
                >
                  发送提示
                </mdui-button>

            <!-- EPG节目表 -->
            <!-- <h1>EPG节目表</h1>
            <input type="file" :id="'epg_file-' + room.room_id" name="epg_file" accept=".xml" />
            <mdui-button style="margin-top: 15px;">上传并保存</mdui-button>
            <br />
            <p>
              不知道如何编写EPG文件？
              <a href="https://ls.vds.pub/legal/assets/sample_epg.xml" target="_blank">来这里，下载范例文件，我们帮你。</a>
            </p> -->

            <div style="margin-bottom: 75px;"></div>
          </mdui-tab-panel>
        </template>
      </mdui-tabs>

      <!-- 没有直播间时的提示 -->
      <div v-if="!loading && !error && liveRooms.length === 0" class="no-rooms">
        <p>您目前没有任何直播间。</p>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import { snackbar, Tabs } from 'mdui'; // 导入 snackbar 功能

export default {
  name: 'AnchorPortal',
  data() {
    return {
      liveRooms: [], // 直播间列表
      currentTab: '', // 当前标签
      error: null, // 错误信息
      loading: true, // 加载状态
    };
  },
  methods: {
    openChatWindow(roomId) {
    const chatWindowUrl = `${window.location.origin}/anchorportal/chat-window?roomId=${roomId}`;
    const chatWindow = window.open(
      chatWindowUrl,
      'ChatWindow',
      'width=400,height=700,resizable=yes,scrollbars=no,toolbar=no,menubar=no,location=no,directories=no,status=no'
    );

    if (!chatWindow || chatWindow.closed || typeof chatWindow.closed == 'undefined') {
      alert('弹出窗口被浏览器拦截，请允许弹出窗口以使用此功能。');
    }
  },

  // 发送互动弹窗消息
  async sendInteraction(roomId) {
  const message = document.getElementById('message').value; // 从输入框获取消息内容

  // 检查 message 是否存在
  if (!message.trim()) {
    alert('请输入消息内容');
    return;
  }

  // 发起 POST 请求，将 room_id 和 message 发送到后端
  axios.post('https://vdslivestream-servicesedge.hydun.com/myaccess/send-interaction', {
    room_id: roomId,
    message: message,
  })
  .then(response => {
    if (response.data.success) {
          snackbar({
            message: '互动消息已发送。',
            position: 'top',
            closeable: true,
          });
          // 清空输入框
          document.getElementById('message').value = "";
        } else {
          snackbar({
            message: response.data.message || '发送互动消息失败。',
            position: 'top',
            type: 'danger',
            closeable: true,
          });
        }
  })
  .catch(error => {
    console.error('发送互动消息请求失败:', error);
  })
  .finally(() => {
    // 清空输入框
    document.getElementById("message").value = "";
  });
},

    async fetchLiveRooms() {
      try {
        const token = localStorage.getItem('token');
        if (!token) {
          this.error = '未找到认证令牌，请重新登录。';
          this.loading = false;
          return;
        }

        const response = await axios.get(
          'https://1.13.182.201:5688/myaccess/getrooms',
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (response.data.success) {
          this.liveRooms = response.data.rooms.map((room) => ({
            ...room,
            loading: false, // 初始化 loading 状态
          }));
          if (this.liveRooms.length > 0) {
            this.currentTab = 'tab-' + this.liveRooms[0].room_id;
          }
          // 初始化 tabs
          this.initTabs();
        } else {
          this.error = response.data.message || '无法获取直播间信息。';
        }
      } catch (err) {
        this.error = err.response?.data?.message || '无法获取直播间信息。';
      } finally {
        this.loading = false;
      }
    },
    async toggleLive(room_id, currentStatus) {
      const roomIndex = this.liveRooms.findIndex(
        (room) => room.room_id === room_id
      );
      if (roomIndex !== -1) {
        this.liveRooms[roomIndex].loading = true; // 开始加载
      }

      try {
        const token = localStorage.getItem('token');
        if (!token) {
          this.error = '未找到认证令牌，请重新登录。';
          return;
        }

        const action = currentStatus === 'on' ? 'off' : 'on';

        const response = await axios.post(
          'https://1.13.182.201:5688/myaccess/togglelive',
          {
            roomId: room_id,
            action,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (response.data.success) {
          // 更新直播间状态
          if (roomIndex !== -1) {
            this.liveRooms[roomIndex].status = action;
          }

          // 显示成功消息
          snackbar({
            message: `直播已${action === 'on' ? '开始' : '结束'}。`,
            position: 'top',
            closeable: true,
          });
        } else {
          // 显示后端返回的错误消息
          snackbar({
            message:
              response.data.message ||
              `直播${action === 'on' ? '开始' : '结束'}失败。`,
            position: 'top',
            type: 'danger',
            closeable: true,
          });
        }
      } catch (err) {
        const action = currentStatus === 'on' ? '结束' : '开始';
        const message =
          err.response?.data?.message || `直播${action}失败。`;
        snackbar({
          message,
          position: 'top',
          type: 'danger',
          closeable: true,
        });
      } finally {
        if (roomIndex !== -1) {
          this.liveRooms[roomIndex].loading = false; // 加载结束
        }
      }
    },
    copyText(elementId) {
      const textField = document.getElementById(elementId);
      if (textField) {
        const input = document.createElement('input');
        input.value = textField.value;
        document.body.appendChild(input);
        input.select();
        document.execCommand('copy');
        document.body.removeChild(input);

        // 显示复制成功消息
        snackbar({
          message: '已复制。',
          position: 'top',
        });
      }
    },
    initTabs() {
      // 使用 mdui 的 JS API 初始化 tabs，并设置当前选中的标签
      this.$nextTick(() => {
        if (this.$refs.tabs) {
          // 初始化 mdui-tabs
          new Tabs(this.$refs.tabs);
        }
      });
    },
  },
  mounted() {
    this.fetchLiveRooms(); // 获取直播间信息
  },
};
</script>

<style scoped>
/* 自定义样式 */
.card {
  text-align: center;
}

.card img {
  width: 100px;
  margin-top: 16px;
}

.content {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 0;
  margin: 0;
}

.mdui-tab-panel {
  flex-grow: 1;
  overflow-y: auto;
}

h1 {
  margin: 20px 0;
}

/* 错误信息样式 */
.error-message {
  color: red;
  margin: 20px;
}

/* 没有直播间时的提示 */
.no-rooms {
  text-align: center;
  margin-top: 50px;
  color: #bdbdbd;
}

/* 加载指示器样式 */
.loading-indicator {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  color: #ffffff;
}

/* 平滑展开隐藏内容的过渡动画 */
.expand-enter-active,
.expand-leave-active {
  transition: max-height 0.5s ease;
}

.expand-enter,
.expand-leave-to {
  max-height: 0;
  overflow: hidden;
}

/* 设置 max-height 的初始值，以适应实际内容的高度 */
.live-hidden-content {
  max-height: 500px;
  /* 可以根据内容的最大高度设置此值 */
  overflow: hidden;
}
</style>