<template>
  <div id="all-live-rooms-container">
    <h1>全部直播</h1>

    <div v-if="loading" class="loading-spinner">
      <mdui-circular-progress></mdui-circular-progress>
    </div>

    <div v-if="!loading" class="live-rooms-grid" :class="{ darkened: isPreviewing }">
      <LiveRoomCard
        v-for="(room, index) in liveRooms"
        :key="room.room_id"
        :room="room"
        :position="getPosition(index)"
        @preview-activated="onPreviewActivated"
        @preview-deactivated="onPreviewDeactivated"
      />
    </div>
  </div>
</template>

<script>
import { ref, onMounted, onBeforeUnmount, computed } from 'vue';
import LiveRoomCard from '@/components/LiveRoomCard.vue';

export default {
  name: 'AllLiveRoomsPage',
  components: {
    LiveRoomCard,
  },
  setup() {
    const liveRooms = ref([]);
    const loading = ref(true); 
    const isPreviewing = ref(false); 

    const fetchAllLiveRooms = async () => {
      try {
        const response = await fetch('https://static.vdsentnet.com/lsapi/check_all_live_rooms/');
        const data = await response.json();
        liveRooms.value = data;
      } catch (error) {
        console.error('请求全部直播间数据时出错:', error);
      } finally {
        loading.value = false; 
      }
    };

    const onPreviewActivated = () => {
      isPreviewing.value = true;
    };

    const onPreviewDeactivated = () => {
      isPreviewing.value = false;
    };

    const colsPerRow = computed(() => {
      const containerWidth = document.getElementById("all-live-rooms-container").clientWidth;
      const cardWidth = 180; // 假设每张卡片宽度为 180px
      return Math.floor(containerWidth / cardWidth);
    });

    const getPosition = (index) => {
      const cols = colsPerRow.value;
      if (index % cols === 0) return 'first';
      if ((index + 1) % cols === 0 || index === liveRooms.value.length - 1) return 'last';
      return 'middle';
    };

    onMounted(() => {
      fetchAllLiveRooms();
      window.addEventListener('resize', () => colsPerRow.value); // 触发 colsPerRow 重新计算
    });

    onBeforeUnmount(() => {
      window.removeEventListener('resize', () => colsPerRow.value);
    });

    return {
      liveRooms,
      loading,
      isPreviewing,
      onPreviewActivated,
      onPreviewDeactivated,
      getPosition,
    };
  },
};
</script>

<style scoped>
#all-live-rooms-container {
  display: flex;
  flex-direction: column;
  padding: 25px;
}

h1 {
  font-size: 2rem;
  text-align: left;
  margin-bottom: 20px;
}

.loading-spinner {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px;
}

.live-rooms-grid {
  display: grid;
  gap: 20px;
  grid-template-columns: repeat(auto-fill, minmax(180px, 1fr));
}

@media (max-width: 600px) {
  .live-rooms-grid {
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
  }

  h1 {
    font-size: 1.5rem;
  }

  #all-live-rooms-container {
    padding: 0px;
  }
}
</style>