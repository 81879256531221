<template>
  <div class="search-results-container">
    <!-- 显示搜索结果的标题 -->
    <h1>{{ searchTerm }} 的搜索结果</h1>

    <!-- 加载中的进度条 -->
    <mdui-circular-progress v-if="loading"></mdui-circular-progress>

    <!-- 直播间的横滚卡片排列 -->
    <div v-if="!loading">
      <div class="section-header" v-if="liveRooms.length > 0">
        <h2>直播间</h2>
        <div class="scroll-buttons">
          <button @click="scrollLeft" class="scroll-button">←</button>
          <button @click="scrollRight" class="scroll-button">→</button>
        </div>
      </div>

      <div class="live-rooms-container" ref="liveContainer" v-if="liveRooms.length > 0">
        <div class="cards-container">
          <LiveRoomCard v-for="room in liveRooms" :key="room.room_id" :room="room" />
        </div>
      </div>

      <div class="live-rooms-container" ref="radioContainer" v-if="radioStations.length > 0">
        <div class="cards-container">
          <RadioCard v-for="room in radioStations" :key="room.room_id" :room="room" />
        </div>
      </div>

      <div class="live-rooms-container" ref="userContainer" v-if="users.length > 0">
        <div class="cards-container">
          <UserCard v-for="user in users" :key="user.UDID" :user="user" />
        </div>
      </div>

      <!-- MCN的横滚卡片排列 -->
      <div class="section-header" v-if="mcnList.length > 0">
        <h2>MCN</h2>
        <div class="scroll-buttons">
          <button @click="scrollLeftMcn" class="scroll-button">←</button>
          <button @click="scrollRightMcn" class="scroll-button">→</button>
        </div>
      </div>

      <div class="live-rooms-container" ref="mcnContainer" v-if="mcnList.length > 0">
        <div class="cards-container">
          <UserCard v-for="mcn in mcnList" :key="mcn.UDID" :user="mcn" />
        </div>
      </div>

      <!-- 没有结果时的提示 -->
      <p v-if="liveRooms.length === 0 && radioStations.length === 0 && users.length === 0 && mcnList.length === 0">
        未找到与 "{{ searchTerm }}" 相关的直播间、用户或MCN。
      </p>
    </div>
  </div>
</template>

<script>
import { ref, onMounted, watch } from 'vue';
import { useRoute } from 'vue-router';
import LiveRoomCard from '@/components/LiveRoomCard.vue';
import RadioCard from '@/components/AudioRoomCard.vue';
import UserCard from '@/components/UserCard.vue';

export default {
  name: 'SearchResultsPage',
  components: {
    LiveRoomCard,
    RadioCard,
    UserCard,
  },
  setup() {
    const route = useRoute();
    const searchTerm = ref(decodeURIComponent(route.params.searchterm));
    const liveRooms = ref([]);
    const radioStations = ref([]);
    const users = ref([]);
    const mcnList = ref([]);
    const liveContainer = ref(null);
    const radioContainer = ref(null);
    const userContainer = ref(null);
    const mcnContainer = ref(null);
    const loading = ref(true);

    const fetchSearchResults = async () => {
      loading.value = true;
      try {
        // 获取直播间搜索结果
        const liveResponse = await fetch(`https://static.vdsentnet.com/lsapi/search/room/?term=${encodeURIComponent(searchTerm.value)}`);
        const liveData = await liveResponse.json();
        liveRooms.value = Array.isArray(liveData) && liveData.length > 0 ? liveData : [];

        // 获取MCN搜索结果
        const mcnResponse = await fetch(`https://static.vdsentnet.com/lsapi/search/MCN/?term=${encodeURIComponent(searchTerm.value)}`);
        const mcnData = await mcnResponse.json();
        mcnList.value = Array.isArray(mcnData) && mcnData.length > 0 ? mcnData : [];
      } catch (error) {
        console.error('请求搜索结果失败', error);
      } finally {
        loading.value = false;
      }
    };

    // 滚动函数
    const scrollLeft = () => { /* ... */ };
    const scrollRight = () => { /* ... */ };
    const scrollLeftRadio = () => { /* ... */ };
    const scrollRightRadio = () => { /* ... */ };
    const scrollLeftUsers = () => { /* ... */ };
    const scrollRightUsers = () => { /* ... */ };
    const scrollLeftMcn = () => { /* ... */ };
    const scrollRightMcn = () => { /* ... */ };

    watch(route, () => {
      searchTerm.value = decodeURIComponent(route.params.searchterm);
      fetchSearchResults();
    });

    onMounted(() => {
      fetchSearchResults();
    });

    return {
      searchTerm,
      liveRooms,
      radioStations,
      users,
      mcnList,
      liveContainer,
      radioContainer,
      userContainer,
      mcnContainer,
      loading,
      scrollLeft,
      scrollRight,
      scrollLeftRadio,
      scrollRightRadio,
      scrollLeftUsers,
      scrollRightUsers,
      scrollLeftMcn,
      scrollRightMcn,
    };
  },
};
</script>

<style scoped>
.section-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 10px;
}

.scroll-buttons {
  display: flex;
  gap: 5px;
}

.scroll-button {
  border: none;
  color: rgb(0, 0, 0);
  padding: 5px 10px;
  cursor: pointer;
  border-radius: 3px;
}

.scroll-button:hover {
  background-color: #d3d3d3;
}

.live-rooms-container {
  display: flex;
  gap: 10px;
  overflow-x: auto;
  position: relative;
  min-height: 100px;
  white-space: nowrap;
  scroll-snap-type: x mandatory;
}

.cards-container {
  display: flex;
  gap: 10px;
}

.cards-container>* {
  scroll-snap-align: start;
}
</style>