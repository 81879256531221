<template>
  <router-link :to="userLink" class="no-decoration">
    <mdui-card class="card" href="/space/" style="width: 250px; height: 174px;">
      <div class="user-info-container">
        <img :src="user.avtr_url" alt="User Avatar" class="avatar" />
        <div class="user-details">
          <div class="name-container">
            <h3 class="user-name">{{ user.nickname }}</h3>
            <img v-if="verificationIcon" :src="verificationIcon" alt="认证标志" class="verification-icon" />
          </div>
          <p class="user-udid">@{{ user.UDID }}</p>
        </div>
      </div>
    </mdui-card>
  </router-link>
</template>

<script>
export default {
  name: 'UserCard',
  props: {
    user: {
      type: Object,
      required: true,
    },
  },
  computed: {
    verificationIcon() {
      switch (this.user.press) {
        case 'gold':
          return '/pb-image/presses/official-press.png';
        case 'green':
          return '/pb-image/presses/enterprise-press.png';
        case 'blue':
          return '/pb-image/presses/personal-press.png';
        default:
          return null;
      }
    },
    userLink() {
      // 判断是MCN还是普通用户
      if (this.user.type === 'mcn') {
        return `/space/mcn/${this.user.UDID}`;
      } else {
        return `/space/${this.user.UDID}`;
      }
    },
  },
};
</script>

<style scoped>
.card {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  height: 100%;
}

.user-info-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.avatar {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  margin-right: 10px;
}

.user-details {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.name-container {
  display: flex;
  align-items: center;
}

.user-name {
  margin: 0;
  color: rgb(255, 255, 255);
  text-decoration: none;
}

.verification-icon {
  width: 16px;
  height: 16px;
  margin-left: 5px;
}

.user-udid {
  margin: 0;
  color: rgb(255, 255, 255);
  text-decoration: none;
  font-size: 14px;
}

.no-decoration {
  text-decoration: none;
}
</style>