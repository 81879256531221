<template>
  <div class="err-container">
    <h1>非常抱歉，你需要登录才能访问这个页面。</h1>
    <!-- <a href="https://support.vds.pub/" class="link">为什么？</a> -->
    <router-link :to="{ path: '/login', query: { redirecturi: redirecturi } }" class="no-decoration">
      <mdui-button>现在登录</mdui-button>
    </router-link>
  </div>
</template>

<script>
export default {
  name: 'NeedLoginPage',
  computed: {
    redirecturi() {
      return this.$route.query.redirecturi || '/';
    },
  },
};
</script>

<style scoped>
.err-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  /* 使容器填满视窗高度 */
  text-align: center;
  /* 文本居中 */
}

.link {
  color: gray;
  /* 链接颜色改为灰色 */
  text-decoration: none;
  /* 去除下划线 */
  margin-bottom: 20px;
  /* 标题下方的间距 */
}

.link:hover {
  text-decoration: underline;
  /* 鼠标悬停时添加下划线 */
}

.no-decoration {
  text-decoration: none;
  /* 移除路由链接的下划线 */
}

p {
  margin-bottom: 20px;
  /* 链接下方的间距 */
}

.no-decoration {
  text-decoration: none;
  /* 移除链接的下划线 */
}
</style>