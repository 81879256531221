<template>
  <div class="search-container">
    <h1>搜索VDS LiveStream</h1>
    <mdui-text-field variant="outlined" label="输入你想查找的内容" v-model="searchTerm" :class="{ 'error-input': errorMessage }"
      @input="handleInput" @keyup.enter="performSearch" id="search-input">
      <!-- eslint-disable-next-line vue/no-deprecated-slot-attribute -->
      <mdui-button-icon slot="end-icon" icon="search" :disabled="searchTerm.trim() === ''"
        @click="performSearch"></mdui-button-icon>
    </mdui-text-field>
    <!-- 错误消息提示 -->
    <p v-if="errorMessage" class="error-message">{{ errorMessage }}</p>
  </div>
</template>

<script>
import { ref } from 'vue';
import { useRouter } from 'vue-router';

export default {
  name: 'SearchPage',
  setup() {
    const searchTerm = ref('');
    const router = useRouter();
    const errorMessage = ref('');

    const handleInput = (event) => {
      const inputValue = event.target.value;
      searchTerm.value = inputValue;

      // 你可以在这里添加更多处理逻辑
      if (inputValue.trim() === '') {
        errorMessage.value = '搜索内容不能为空。';
      } else {
        errorMessage.value = ''; // 清空错误消息
      }
    };

    const performSearch = () => {
      if (searchTerm.value.trim() === '') {
        errorMessage.value = '搜索内容不能为空。';
      } else {
        errorMessage.value = ''; // 清空错误消息
        router.push(`/search/${encodeURIComponent(searchTerm.value.trim())}`);
      }
    };

    return {
      searchTerm,
      performSearch,
      errorMessage,
      handleInput,
    };
  },
};
</script>

<style scoped>
.error-message {
  color: red;
  margin-top: 10px;
  animation: fadeIn 0.3s ease-in-out;
}

.error-input {
  border-color: red !important;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}
</style>