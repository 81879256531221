import { reactive } from 'vue';

export const authState = reactive({
  isLoggedIn: !!localStorage.getItem('token'), // 初始化时检查本地存储
  defaultAvatar: '/pb-image/login-avtr.png', // 默认未登录头像
});

export const setLoginState = (isLoggedIn, avatarUrl = null) => {
  authState.isLoggedIn = isLoggedIn;
  if (isLoggedIn && avatarUrl) {
    authState.loggedInAvatar = avatarUrl;
    window.dispatchEvent(new Event('loginStateChange'));
  }
};

export const logout = () => {
  authState.isLoggedIn = false;
  localStorage.removeItem('token');
  localStorage.removeItem('avatarUrl');
  window.dispatchEvent(new Event('loginStateChange'));
};