import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import mitt from 'mitt';
import 'mdui/mdui.css';  // Correct file path for CSS
import 'mdui';  // Correct file path for JavaScript

const emitter = mitt();

const app = createApp(App);
app.config.compilerOptions.isCustomElement = tag => tag.startsWith('mdui-');
app.config.globalProperties.$emitter = emitter; // 全局注入事件总线
app.use(router).mount('#LSapp');