<template>
  <div class="login-container">
    <div class="left-side">
      <div class="promo-content">
        <img src="@/assets/vds-livestream-small-white.png" class="logo" alt="Logo" />
        <h1 class="slogan">
          在
          <span class="scrolling-container" :style="{ width: dynamicWidth }">
            <transition-group name="scroll" tag="div" class="scrolling-text">
              <span v-if="currentPlace" :key="currentPlace" class="place-text">{{ currentPlace }}</span>
            </transition-group>
          </span>
          轻松看直播，处处都精彩
        </h1>
        <h2>欢迎加入 VDS LiveStream</h2>
        <p>体验优质的直播内容，在我们的社区，与全球观众互动。</p>
      </div>
    </div>
    <div class="right-side">
      <mdui-card class="mdui-card">
        <form @submit.prevent="registerUser">
          <img src="@/assets/vds-livestream-small-white.png" class="logo-small" alt="Logo" />
          <h1>注册</h1>
          <div class="input-container">
            <label for="email">电子邮件地址:</label>
            <mdui-text-field ref="emailField" type="text" id="email" required variant="outlined"></mdui-text-field>
          </div>
          <div style="margin-top: 10px;">
            <label for="password">密码:</label>
            <mdui-text-field ref="passwordField" type="password" id="password" required variant="outlined"></mdui-text-field>
          </div>
          <mdui-button full-width type="submit" :disabled="isSubmitting" style="margin-top: 10px;">注册</mdui-button>
          <p v-if="successMessage" class="success-message">{{ successMessage }}</p>
          <p v-if="errorMessage" class="error-message">{{ errorMessage }}</p>
        </form>
      </mdui-card>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  name: 'RegisterPage',
  data() {
    return {
      isSubmitting: false,
      successMessage: '',
      errorMessage: '',
      places: [
        '家中', '摸鱼时', '甲板上', '地铁上', '咖啡馆里', '淋雨时', '等飞机时', 
        '聚餐时', '公园里', '等待时', '游戏中', '出毛时', '车上时', '洗漱时', 
        '编程时', '做饭时', '这条彩蛋时', '唠嗑时', '兽聚时', 'VR时', 
        '炒菜时', '脑子好炸时', '如厕时', '剪片时', '钓鱼时', '沙发上', 
        '树下乘凉时', '赏月时', '看星星时', '放松时', '听音乐时', '看海时', 
        '野营时', '登山时', '骑车时', '远足时',
      ],
      currentPlace: '',
      dynamicWidth: '3em',
    };
  },
  methods: {
    async registerUser() {
      const email = this.$refs.emailField.value; // 获取电子邮件输入框的值
      const password = this.$refs.passwordField.value; // 获取密码输入框的值

      if (!email || !password) {
        this.errorMessage = '邮箱和密码不能为空';
        return;
      }

      this.isSubmitting = true;
      this.successMessage = '';
      this.errorMessage = '';

      try {
        const response = await axios.post('https://1.13.182.201:5688/register', {
          email: email,
          password: password,
        });

        if (response.data.success) {
          this.successMessage = '注册成功！';
        } else {
          this.errorMessage = response.data.message || '注册失败';
        }
      } catch (error) {
        this.errorMessage = '请求失败，请稍后再试';
      } finally {
        this.isSubmitting = false;
      }
    },
    rotatePlaces() {
      const randomIndex = Math.floor(Math.random() * this.places.length);
      this.currentPlace = this.places[randomIndex];
      this.updateWidth();
    },
    updateWidth() {
      const tempElement = document.createElement('span');
      tempElement.style.position = 'absolute';
      tempElement.style.visibility = 'hidden';
      tempElement.style.whiteSpace = 'nowrap';
      tempElement.innerText = this.currentPlace;
      document.body.appendChild(tempElement);

      const additionalWidth = 40; // 可根据需要调整
      this.dynamicWidth = `${tempElement.offsetWidth + additionalWidth}px`;

      document.body.removeChild(tempElement);
    },
  },
  mounted() {
    this.rotatePlaces();
    this.interval = setInterval(this.rotatePlaces, 2000); // 每2秒随机切换
  },
  beforeUnmount() {
    clearInterval(this.interval); // 组件卸载时清除定时器
  },
};
</script>

<style scoped>
/* 样式与登录页面相同 */
.logo {
  height: 60px;
}

.logo-small {
  height: 30px;
  display: none;
}

.login-container {
  display: flex;
  height: 100%;
  overflow: hidden;
}

.left-side {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
}

.right-side {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
}

.mdui-card {
  padding: 20px;
  max-width: 400px;
  width: 100%;
}

h1 {
  margin-bottom: 20px;
}

.promo-content {
  text-align: center;
}

.slogan {
  display: flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
  font-size: 24px;
  overflow: hidden;
  height: 33.5px;
}

.scrolling-container {
  display: inline-block;
  vertical-align: middle;
  overflow: hidden;
  transition: width 0.5s ease;
  line-height: 33.5px;
  height: 33.5px;
}

.scrolling-text {
  display: flex;
  color: #007bff;
  flex-direction: column;
  transition: transform 0.5s ease, opacity 0.5s ease;
  align-items: center;
  height: 33.5px;
}

.place-text {
  line-height: 33.5px;
  height: 33.5px;
  display: inline-block;
}

.scroll-enter-active,
.scroll-leave-active {
  transition: transform 0.5s ease, opacity 0.5s ease;
  position: absolute;
}

.scroll-enter-from {
  transform: translateY(100%);
  opacity: 0;
}

.scroll-enter-to {
  transform: translateY(0%);
  opacity: 1;
}

.scroll-leave-from {
  transform: translateY(0%);
  opacity: 1;
}

.scroll-leave-to {
  transform: translateY(-100%);
  opacity: 0;
}

label {
  display: block;
  margin-bottom: 5px;
}

.error-message {
  color: red;
  margin-top: 10px;
}

.success-message {
  color: green;
  margin-top: 10px;
}

@media (max-width: 600px) {
  .left-side {
    display: none;
  }

  .logo-small {
    display: block;
  }
}
</style>