<template>
    <div id="chat-window">
      <!-- 聊天容器 -->
      <div id="chat-container">
        <!-- 聊天容器的标题和在线人数 -->
        <div id="chat-header">
          <h2 style="margin-left: 15px;margin-bottom: 0;">实时聊天</h2>
          <!-- 显示在线人数 -->
          <div id="onlineUsersCountWrapper">
            <div id="onlineUsersDot"></div>
            <span id="onlineUsersCount">{{ onlineUsersText }}</span>
          </div>
          <div style="margin-bottom: 15px"></div>
        </div>
        <div v-if="isChatVisible" id="chat-messages-container">
          <div id="chat-messages"></div>
        </div>
        <div id="chat-closed-message-container" v-else>
          <h2>直播已关闭实时聊天。</h2>
        </div>
        <mdui-text-field
          :label="isChatVisible ? (isLoggedIn ? '在实时聊天中讨论' : '登录以发送消息') : '实时聊天已停用'"
          :disabled="!isLoggedIn || !isChatVisible"
          id="chat-input"
          variant="filled"
          type="text"
          name=""
          @keyup.enter="sendMessage"
        >
          <!-- eslint-disable-next-line vue/no-deprecated-slot-attribute -->
          <mdui-button-icon slot="end-icon"
            icon="send"
            id="sendsms"
            @click="sendMessage"
            type="button"
            variant="standard"
          ></mdui-button-icon>
          <!-- eslint-disable-next-line vue/no-deprecated-slot-attribute -->
          <mdui-button-icon slot="icon"
            icon="image"
            id="sendimg"
            @click="selectImage"
            type="button"
            variant="standard"
            :disabled="isSendingImage"
            :loading="isSendingImage"
          ></mdui-button-icon>
        </mdui-text-field>
        <!-- 隐藏的文件选择器 -->
        <input type="file" ref="fileInput" accept="image/*" style="display: none" @change="handleImageUpload">
      </div>
    </div>
  </template>
  
  <script>
  import { ref, onMounted, computed, onBeforeUnmount } from 'vue';
  import io from 'socket.io-client';
  import axios from 'axios';
  import Compressor from 'compressorjs'; // 使用 Compressor.js 进行图片压缩
  
  export default {
    name: 'ChatWindow',
    setup() {
      const roomId = ref(null);
      const socket = ref(null);
      const onlineUsers = ref(0);
      const liveStatus = ref('off');
      const isChatVisible = ref(true);
      const isLoggedIn = ref(false);
      const isSendingImage = ref(false);
  
      const onlineUsersText = computed(() => {
        return liveStatus.value === 'on'
          ? `${onlineUsers.value} 人正在观看`
          : `${onlineUsers.value} 人正在等待`;
      });
  
      const checkLoginStatus = async () => {
        const token = localStorage.getItem('token');
        if (token) {
          try {
            const response = await axios.get('https://1.13.182.201:5688/user/info', {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            });
            isLoggedIn.value = response.data.success;
          } catch (error) {
            console.error('Error checking login status:', error);
            isLoggedIn.value = false;
          }
        } else {
          isLoggedIn.value = false;
        }
      };
  
      const setupWebSocket = () => {
        console.log(`Connecting to room with ID: ${roomId.value}`);
  
        socket.value = io('https://1.13.182.201:5688', {
          path: '/vdsls-sys-endpoint',
        });
  
        socket.value.on('connect', () => {
          console.log('WebSocket connected');
          socket.value.emit('request_room_data', roomId.value);
        });
  
        socket.value.on('live_status', (data) => {
          console.log('Live status received:', data);
          liveStatus.value = data.status;
          isChatVisible.value = data.hide_chat !== 'on';
        });
  
        socket.value.on('receive_message', (message) => {
          if (isChatVisible.value) {
            console.log('Received message:', message);
            insertMessageWithAnimation(message, false);
          }
        });
  
        socket.value.on('receive_messages', (messages) => {
          if (isChatVisible.value) {
            console.log('Received multiple messages:', messages);
            messages.forEach((msg) => insertMessageWithAnimation(msg, true));
          }
        });
  
        socket.value.on('update_client_count', (count) => {
          console.log('Online users count received:', count);
          onlineUsers.value = count;
        });
  
        socket.value.on('disconnect', () => {
          console.log('WebSocket disconnected');
        });
      };
  
      const sendMessage = async (content = '', messageType = 'MESSAGE') => {
        if (!isLoggedIn.value) {
          alert('请先登录再发送消息');
          return;
        }
  
        const inputElement = document.querySelector('#chat-input');
        const messageContent = messageType === 'MESSAGE' ? inputElement.value.trim() : content;
  
        if (messageContent) {
          try {
            const response = await axios.get('https://1.13.182.201:5688/user/info', {
              headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`,
              },
            });
  
            if (response.data.success) {
              let displayName = response.data.user.nickname;
              if (!displayName) {
                displayName = response.data.user.UDID || response.data.user.username;
              }
  
              socket.value.emit('send_message', {
                roomId: roomId.value,
                content: messageContent,
                token: localStorage.getItem('token'),
                nickname: displayName,
                messageType: messageType, // 指定消息类型
              });
  
              if (messageType === 'MESSAGE') {
                inputElement.value = ''; // 清空输入框
              }
            } else {
              console.error('无法获取用户信息');
            }
          } catch (error) {
            console.error('获取用户信息失败:', error);
          }
        }
      };
  
      const sendImageMessage = (imageUrl) => {
        sendMessage(imageUrl, 'IMAGE');
      };
  
      const selectImage = () => {
        const fileInput = document.createElement('input');
        fileInput.type = 'file';
        fileInput.accept = 'image/*';
        fileInput.click();
        fileInput.onchange = (event) => {
          const file = event.target.files[0];
          if (file) {
            compressAndUploadImage(file);
          }
        };
      };
  
      const compressAndUploadImage = (file) => {
        isSendingImage.value = true; // 显示加载状态
  
        // 使用 Compressor.js 压缩图片
        new Compressor(file, {
          quality: 0.6, // 压缩质量
          maxWidth: 500, // 设置最大宽度为 500 像素
          success(result) {
            // 将压缩后的图片上传
            const formData = new FormData();
            formData.append('image', result);
  
            axios.post('https://1.13.182.201:5688/upload-image', formData, {
              headers: {
                'Content-Type': 'multipart/form-data',
                Authorization: `Bearer ${localStorage.getItem('token')}`,
              },
            })
              .then(response => {
                if (response.data.success) {
                  // 将图片地址作为消息发送
                  sendImageMessage(response.data.imageUrl);
                } else {
                  alert('图片上传失败');
                }
              })
              .catch(() => {
                alert('图片上传失败');
              })
              .finally(() => {
                isSendingImage.value = false; // 关闭加载状态
              });
          },
          error(err) {
            console.error('图片压缩失败:', err);
            isSendingImage.value = false;
          },
        });
      };
  
      const insertMessageWithAnimation = (message, noAnimation) => {
        if (!isChatVisible.value) return;
  
        const chatMessagesContainer = document.getElementById('chat-messages');
        const messageElement = document.createElement('div');
  
        // 实现消息渲染逻辑
        if (message.messageType === 'IMAGE') {
          messageElement.classList.add('chat-message');
          messageElement.innerHTML = `
            <div style="margin-bottom: 10px;">
              <span class="username" style="margin-right: 5px; color: rgba(164, 164, 164, 1); font-size: 13px;">${message.username}</span>
              <img src="${message.content}" alt="Image" style="width: 100px; height: auto; display: block;" />
            </div>`;
        } else if (message.messageType === 'INFO' || message.messageType === 'WARN') {
          // 处理系统消息
          messageElement.classList.add('info-message');
          messageElement.innerHTML = `
            <div class="card-messages" style="background-color: ${message.messageType === 'WARN' ? '#ff4d4d' : '#e6a800'}; color: #fff; padding: 10px; border-radius: 5px;">
              <div style="display: flex; align-items: center; margin-bottom: 5px;">
                <img src="data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath fill='%23fff' fill-rule='evenodd' d='M8.893 1.5c-.183-.31-.52-.5-.887-.5s-.703.19-.886.5L.138 13.499a.98.98 0 0 0 0 1.001c.193.31.53.501.886.501h13.964c.367 0 .704-.19.877-.5a1.03 1.03 0 0 0 .01-1.002L8.893 1.5zm.133 11.497H6.987v-2.003h2.039v2.003zm0-3.004H6.987V5.987h2.039v4.006z'/%3E%3C/svg%3E" style="width: 16px; height: 16px; margin-right: 8px;" />
                <span style="font-weight: bold; color: #fff;">${message.messageType === 'WARN' ? '警告' : '注意'}</span>
              </div>
              <div style="color: #fff; font-size: 12px;">
                <span style="font-weight: bold; margin-right: 5px;">${message.username}</span>
                ${message.content}
              </div>
            </div>`;
        } else {
          messageElement.classList.add('chat-message');
          const badge = message.userType === 'SYSTEM-ADMIN' ? `<span class="admin-badge">超管</span>` : '';
  
          messageElement.innerHTML = `
            <span style="font-size: 13px;">
              <span class="username" style="margin-right: 5px; color: rgba(164, 164, 164, 1);">
                ${message.username}${badge}
              </span>
              ${message.content}
            </span>`;
        }
  
        if (noAnimation) {
          chatMessagesContainer.insertBefore(messageElement, chatMessagesContainer.firstChild);
        } else {
          messageElement.style.opacity = 0;
          chatMessagesContainer.appendChild(messageElement);
  
          setTimeout(() => {
            messageElement.style.transition = 'opacity 0.2s';
            messageElement.style.opacity = 1;
          }, 0);
  
          chatMessagesContainer.scrollTop = chatMessagesContainer.scrollHeight;
        }
      };
  
      onMounted(() => {
        // 从 URL 参数中获取 roomId
        const urlParams = new URLSearchParams(window.location.search);
        roomId.value = urlParams.get('roomId');
  
        if (!roomId.value) {
          alert('缺少 roomId 参数');
          window.close();
          return;
        }
  
        checkLoginStatus();
        setupWebSocket();
        window.dispatchEvent(new Event('chatwindowOpened'));
      });
  
      onBeforeUnmount(() => {
        if (socket.value) {
          socket.value.disconnect();
          console.log('WebSocket disconnected');
        }
      });
  
      return {
        onlineUsersText,
        isChatVisible,
        isLoggedIn,
        isSendingImage,
        sendMessage,
        selectImage,
      };
    },
  };
  </script>
  
  <style scoped>
  #chat-window {
    width: 100%;
    height: 100%;
    background-color: rgba(50, 50, 50, 1);
    color: #ffffff;
    display: flex;
    flex-direction: column;
  }
  
  #chat-container {
    flex: 1;
    display: flex;
    flex-direction: column;
  }
  
  #chat-header {
    position: sticky;
    top: 0;
    background-color: rgb(22, 22, 22);
    z-index: 1;
    padding: 3px;
    box-sizing: border-box;
  }
  
  #chat-messages-container {
    flex: 1;
    overflow-y: auto;
    padding: 15px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column-reverse;
  }
  
  #chat-closed-message-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    color: gray;
  }
  
  .chat-message {
    margin-top: 10px;
    margin-bottom: 10px;
    font-size: 8px;
    opacity: 0;
    transition: opacity 0.2s ease-in-out;
  }
  
  .info-message {
    margin-top: 10px;
    margin-bottom: 10px;
    opacity: 0;
    transition: opacity 0.2s ease-in-out;
  }
  
  .username {
    margin-right: 5px;
    color: rgba(164, 164, 164, 1);
  }
  
  #onlineUsersCountWrapper {
    display: inline-block;
    margin-left: 15px;
    font-size: 14px;
  }
  
  #onlineUsersDot {
    width: 8px;
    height: 8px;
    background-color: red;
    border-radius: 50%;
    display: inline-block;
    margin-right: 5px;
  }
  
  #chat-input {
    padding-left: 15px;
    padding-right: 15px;
    padding-bottom: 15px;
  }
  
  .admin-badge {
    background-color: #ffcc00;
    color: #333;
    padding: 2px 4px;
    border-radius: 3px;
    font-size: 10px;
    margin-left: 5px;
    font-weight: bold;
    vertical-align: middle;
  }
  
  .card-messages {
    margin-top: 10px;
  }
  </style>  