<template>
  <div>正在退出登录...</div>
</template>

<script>
import { logout } from '@/auth';

export default {
  name: 'LogoutPage',
  mounted() {
    logout()
    // 重定向到首页或登录页
    this.$router.push('/');
  },
};
</script>

<style scoped>
/* 添加样式（如果需要） */
</style>