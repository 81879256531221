<template>
  <div id="space-container" style="padding: 25px;">
    <!-- 用户状态卡片 -->
    <div v-if="entityStatus !== 0 || yellowMark === 'on'" class="status-banner">
      <div v-if="entityStatus === 1" class="status-card gray">
        <img
          src="data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath fill='%23fff8e6' fill-rule='evenodd' d='M8.893 1.5c-.183-.31-.52-.5-.887-.5s-.703.19-.886.5L.138 13.499a.98.98 0 0 0 0 1.001c.193.31.53.501.886.501h13.964c.367 0 .704-.19.877-.5a1.03 1.03 0 0 0 .01-1.002L8.893 1.5zm.133 11.497H6.987v-2.003h2.039v2.003zm0-3.004H6.987V5.987h2.039v4.006z'/%3E%3C/svg%3E"
          style="width: 16px; height: 16px; margin-right: 8px;">
        <p>该账户已被停权封禁。</p>
      </div>
      <div v-else-if="entityStatus === 2" class="status-card gray">
        <img
          src="data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath fill='%23fff8e6' fill-rule='evenodd' d='M8.893 1.5c-.183-.31-.52-.5-.887-.5s-.703.19-.886.5L.138 13.499a.98.98 0 0 0 0 1.001c.193.31.53.501.886.501h13.964c.367 0 .704-.19.877-.5a1.03 1.03 0 0 0 .01-1.002L8.893 1.5zm.133 11.497H6.987v-2.003h2.039v2.003zm0-3.004H6.987V5.987h2.039v4.006z'/%3E%3C/svg%3E"
          style="width: 16px; height: 16px; margin-right: 8px;">
        <p>该账户已被删除。</p>
      </div>
      <div v-else-if="entityStatus === 3" class="status-card gray">
        <img
          src="data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath fill='%23fff8e6' fill-rule='evenodd' d='M8.893 1.5c-.183-.31-.52-.5-.887-.5s-.703.19-.886.5L.138 13.499a.98.98 0 0 0 0 1.001c.193.31.53.501.886.501h13.964c.367 0 .704-.19.877-.5a1.03 1.03 0 0 0 .01-1.002L8.893 1.5zm.133 11.497H6.987v-2.003h2.039v2.003zm0-3.004H6.987V5.987h2.039v4.006z'/%3E%3C/svg%3E"
          style="width: 16px; height: 16px; margin-right: 8px;">
        <p>该账户正在申请中。</p>
      </div>
      <div v-if="yellowMark === 'on'" class="status-card yellow">
        <img
          src="data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath fill='%23fff8e6' fill-rule='evenodd' d='M8.893 1.5c-.183-.31-.52-.5-.887-.5s-.703.19-.886.5L.138 13.499a.98.98 0 0 0 0 1.001c.193.31.53.501.886.501h13.964c.367 0 .704-.19.877-.5a1.03 1.03 0 0 0 .01-1.002L8.893 1.5zm.133 11.497H6.987v-2.003h2.039v2.003zm0-3.004H6.987V5.987h2.039v4.006z'/%3E%3C/svg%3E"
          style="width: 16px; height: 16px; margin-right: 8px;">
        <p>该账户可能有争议行为，请谨慎判断内容。</p>
      </div>
    </div>

    <!-- 用户头部部分 -->
    <div id="entity_hero_image_content" style="position: relative;">
      <img :src="heroImage" ref="heroImageRef" style="width: 100%; border-radius: 10px;" @load="updateBackgroundHeight">
      <!-- 模糊背景 -->
      <div ref="blurredBg" :style="{
        position: 'absolute',
        top: '0',
        left: '0',
        width: '100%',
        height: `${backgroundHeight}px`,
        backgroundImage: `url(${heroImage})`,
        backgroundSize: 'cover',
        filter: 'blur(15px)',
        zIndex: -1
      }">
      </div>
    </div>

    <!-- 用户信息 -->
    <div class="user-info-container" style="margin-top: 15px;">
      <img :src="avatar" alt="entity avatar" ref="avatarImg" class="shadowed-img">
      <div class="user-info">
        <div id="name-scope" style="position: relative; display: flex; align-items: center;">
          <h1 id="username" style="margin-right: 10px;">{{ entityName }}</h1>
          <mdui-tooltip v-if="isVerified" content="轻点可了解认证的更多详细说明">
            <img v-if="isVerified" :src="verificationIcon" style="width: 20px; height: 20px; cursor: pointer;"
              :alt="verificationText" @click="openHelpCenter">
          </mdui-tooltip>
        </div>
        <p style="margin-top: -25px;color: gray;">{{ entityId }}</p>
        <p style="margin-top: -20px;color: gray;" v-if="verificationText">已认证：{{ verificationText }}</p>
      </div>
    </div>

    <!-- 直播间部分 -->
    <div class="section-header">
      <h2>直播间</h2>
      <div class="scroll-buttons">
        <button @click="scrollLeft" class="scroll-button">←</button>
        <button @click="scrollRight" class="scroll-button">→</button>
      </div>
    </div>
    <div class="live-rooms-container" ref="liveContainer">
      <div class="cards-container">
        <LiveRoomCard v-for="room in liveRooms" :key="room.room_id" :room="room" class="shadowed-img" />
      </div>
    </div>

    <!-- 简介部分 -->
    <div v-if="description" style="margin-top: 15px;">
      <h2>简介</h2>
      <p style="margin-top: -15px;">{{ description }}</p>
    </div>
  </div>
</template>

<script>
import { ref, onMounted, computed, nextTick } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import LiveRoomCard from '@/components/LiveRoomCard.vue';

export default {
  name: 'SpacePage',
  components: {
    LiveRoomCard,
  },
  setup() {
    const route = useRoute();
    const router = useRouter();
    const entityName = ref('');
    const entityId = ref('');
    const avatar = ref('');
    const heroImage = ref('');
    const description = ref('');
    const isVerified = ref(false);
    const verificationType = ref('');
    const liveRooms = ref([]);
    const entityStatus = ref(0);
    const yellowMark = ref('off');
    const backgroundHeight = ref(0);
    const heroImageRef = ref(null);
    const liveContainer = ref(null);

    const fetchEntityData = async () => {
      try {
        const udid = route.params.udid;
        const isMcn = route.path.includes('/mcn');
        const endpoint = isMcn
          ? `https://static.vdsentnet.com/lsapi/fetch_mcn/?udid=${udid}`
          : `https://static.vdsentnet.com/lsapi/fetch_user/?udid=${udid}`;
        const response = await fetch(endpoint);
        const data = await response.json();

        if (data.success) {
          const entity = isMcn ? data.mcn : data.user;
          entityName.value = entity.nickname || '昵称未设置';
          entityId.value = `@${entity.UDID}`;
          avatar.value = entity.avtr_url;
          heroImage.value = entity.banner_url;
          description.value = entity.description;
          entityStatus.value = parseInt(entity.status, 10);
          yellowMark.value = entity.yellow_mark || 'off';
          isVerified.value = entity.press !== null;
          verificationType.value = entity.press;

          liveRooms.value = entity.rooms;
          nextTick(updateBackgroundHeight); // 页面加载时立即计算背景高度
        } else {
          router.push({ name: 'NotFoundPage' });
        }
      } catch (error) {
        console.error('请求实体数据失败', error);
      }
    };

    const verificationText = computed(() => {
      switch (verificationType.value) {
        case 'blue':
          return '该账户已成功完成个人身份认证，在 VDS LiveStream 或 其他平台 有一定影响力。';
        case 'green':
          return '该账户已成功完成组织身份认证，为一个合法的法人实体或民事主体。';
        case 'gold':
          return '该账户已成功完成官方身份认证，为 VDS 各个服务官方所设。';
        default:
          return '';
      }
    });

    const verificationIcon = computed(() => {
      switch (verificationType.value) {
        case 'blue':
          return '/pb-image/presses/personal-press.png';
        case 'green':
          return '/pb-image/presses/enterprise-press.png';
        case 'gold':
          return '/pb-image/presses/official-press.png';
        default:
          return '';
      }
    });

    const openHelpCenter = () => {
      window.open('https://support.vds.pub/services/ls/verification', '_blank');
    };

    const updateBackgroundHeight = () => {
      if (heroImageRef.value) {
        backgroundHeight.value = heroImageRef.value.clientHeight;
      }
    };

    const scrollLeft = () => {
      if (liveContainer.value) {
        liveContainer.value.scrollBy({
          left: -300,
          behavior: 'smooth',
        });
      }
    };

    const scrollRight = () => {
      if (liveContainer.value) {
        liveContainer.value.scrollBy({
          left: 300,
          behavior: 'smooth',
        });
      }
    };

    onMounted(() => {
      fetchEntityData();
      window.addEventListener('resize', updateBackgroundHeight);
    });

    return {
      entityName,
      entityId,
      avatar,
      heroImage,
      description,
      isVerified,
      verificationType,
      verificationText,
      verificationIcon,
      liveRooms,
      entityStatus,
      yellowMark,
      backgroundHeight,
      updateBackgroundHeight,
      heroImageRef,
      liveContainer,
      openHelpCenter,
      scrollLeft,
      scrollRight,
    };
  },
};
</script>

<style scoped>
.shadowed-img img {
  filter: drop-shadow(0 4px 8px rgba(0, 0, 0, 0.4));
}

.user-info-container {
  display: flex;
  align-items: center;
  gap: 20px;
}

.user-info-container img {
  width: 150px;
  height: 150px;
  border-radius: 50%;
}

.user-info {
  flex: 1;
}

.section-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.scroll-buttons {
  display: flex;
  gap: 5px;
}

.scroll-button {
  border: none;
  color: rgb(0, 0, 0);
  padding: 5px 10px;
  cursor: pointer;
  border-radius: 3px;
}

.scroll-button:hover {
  background-color: #d3d3d3;
}

.live-rooms-container {
  display: flex;
  gap: 10px;
  overflow-x: auto;
  position: relative;
  min-height: 100px;
  white-space: nowrap;
  scroll-snap-type: x mandatory;
}

.cards-container {
  display: flex;
  gap: 10px;
}

.cards-container>* {
  scroll-snap-align: start;
}

.loading-spinner {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100px;
}

.status-banner {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-bottom: 10px;
}

.status-card {
  width: 100%;
  text-align: center;
  padding: 10px 0;
  border-radius: 5px;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.gray {
  background-color: #d3d3d3;
  color: #ffffff;
}

.yellow {
  background-color: #e6a900;
  color: #ffffff;
}
</style>