<template>
  <div class="navigation-bar">
    <mdui-card class="navigation-card">
      <img src="@/assets/vds-livestream-small-white.png" class="logo" alt="Logo" />
      <div class="top-right">
        <router-link :to="`/search`" class="no-decoration">
          <mdui-button-icon href="/search" variant="tonal" icon="search"></mdui-button-icon>
        </router-link>
        <router-link :to="linkPath" class="no-decoration">
          <mdui-tooltip :content="tooltipContent">
            <mdui-avatar style="margin-top: -6px; margin-left: 5px;" :src="avatarUrl"></mdui-avatar>
          </mdui-tooltip>
        </router-link>
      </div>
    </mdui-card>
  </div>
</template>

<script>
export default {
  name: 'NavigationBar',
  data() {
    return {
      avatarUrl: localStorage.getItem('avatarUrl') || '/pb-image/login-avtr.png',
      isLoggedIn: !!localStorage.getItem('token'), // 判断是否已登录
    };
  },
  computed: {
    linkPath() {
      return this.isLoggedIn ? '/my' : '/login';
    },
    tooltipContent() {
      return this.isLoggedIn ? '管理' : '轻点登录';
    },
  },
  created() {
    this.updateAvatar();
    // 监听登录状态变化
    window.addEventListener('loginStateChange', this.updateAvatar);
  },
  beforeUnmount() {
    window.removeEventListener('loginStateChange', this.updateAvatar);
  },
  methods: {
    updateAvatar() {
      const token = localStorage.getItem('token');
      this.isLoggedIn = !!token;
      this.avatarUrl = token
        ? localStorage.getItem('avatarUrl') || '/pb-image/login-avtr.png'
        : '/pb-image/login-avtr.png';
    },
  },
};
</script>

<style scoped>
.navigation-bar {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1000;
  /* 确保导航栏位于页面的最上层 */
}

.navigation-card {
  padding: 10px;
  /* 控制卡片内部的填充 */
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-shadow: none;
  /* 去掉默认阴影 */
  border-radius: 0;
  /* 确保背景覆盖整个导航栏 */
}

.logo {
  height: 30px;
  margin-left: 8px;
  /* 添加一些左边距 */
  margin-top: 4px;
}

.top-right {
  display: flex;
  align-items: center;
}

.divider {
  margin-top: 10px;
  margin-bottom: 10px;
}
</style>