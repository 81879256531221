<template>
  <div id="AuroraPlayer" ref="playerContainer"></div>
</template>

<script>
import { nextTick } from 'vue';
import AuroraPlayer from 'auroraplayer';

export default {
  props: {
    options: {
      type: Object,
      required: true,
      default: () => ({}),
    }
  },
  mounted() {
    nextTick(() => {
      if (this.$refs.playerContainer) {
        try {
          // 如果提供了多个 quality 选项，我们移除 video.url，避免与 quality 冲突
          if (this.options.video && this.options.video.quality && this.options.video.quality.length > 0) {
            delete this.options.video.url;
          }

          this.player = new AuroraPlayer({
            container: this.$refs.playerContainer,
            preload: this.options.preload || 'auto',
            ...this.options,
          });
        } catch (error) {
          console.error('Failed to initialize AuroraPlayer:', error);
        }
      } else {
        console.error('Player container not found');
      }
    });
  },
  beforeUnmount() {
    if (this.player) {
      this.player.destroy();
    }
  }
};
</script>

<style scoped>
#AuroraPlayer {
  width: 100%;
  height: 100%;
}
</style>

<style>
.auroraplayer-quality-mask {
  display: none;
  position: absolute;
  bottom: 38px;
  left: -18px;
  width: 80px;
  padding-bottom: 12px;
  opacity: 0;
  transform: translateY(10px);
  transition: all 0.2s ease-in-out;
  animation: slide-up-fade-in 0.2s ease-in-out;
}

@keyframes slide-up-fade-in {
  from {
    opacity: 0;
    transform: translateY(10px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.auroraplayer-icon.auroraplayer-quality-icon:hover~.auroraplayer-quality-mask,
.auroraplayer-quality-mask:hover,
.auroraplayer-quality-list:hover,
.auroraplayer-icon.auroraplayer-quality-icon:hover~.auroraplayer-quality-list {
  display: block;
  opacity: 1;
  transform: translateY(0);
}

.auroraplayer-info-panel-item-url {
  display: none;
}
</style>