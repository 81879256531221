<template>
    <div class="err-container">
        <div class="container">
            <div class="content">
                <img src="@/assets/vds-livestream-small-white.png" alt="一个代表错误的图片">
                <div class="text">
                    <h1>404 Not Found</h1>
                    <p>页面未找到</p>
                    <p style="color: gray;">很抱歉，你请求的页面不存在，这可能是你的问题。可能导致此问题的原因包括页面已被我们删除、链接地址错误或页面暂时不可用。</p>
                    <p style="color: gray;">一只毛茸茸的小伙伴在寻找正确的房间串门，但房间就是找不到，他又看了一下朋友发给他的房号，写着404。</p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'NotFoundPage',
}
</script>

<style scoped>
.err-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    /* 使容器填满视窗高度 */
}

.container {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
}

.content {
    display: flex;
    align-items: center;
}

.content img {
    max-width: 200px;
    margin-right: 20px;
}

.text {
    font-size: 18px;
}

.text h1 {
    font-size: 48px;
    margin: 0;
}

.text p {
    margin: 5px 0;
}

.explanation,
.joke {
    margin-top: 20px;
    font-size: 16px;
    color: #555;
}

.explanation {
    margin-bottom: 10px;
}
</style>