<template>
    <div class="article-container">
      <div class="article-card">
        <div class="article-header">
          <h1 class="article-title">{{ article.title }}</h1>
        </div>
        <MarkdownViewer v-if="articleContent" :content="articleContent" />
        <div v-else class="loading">
          <mdui-circular-progress></mdui-circular-progress>
          加载中...
        </div>
        <div class="article-footer">
          <mdui-button @click="shareArticle">分享</mdui-button>
        </div>
      </div>
    </div>
    <div style="margin-bottom: 70px;"></div>
  </template>

<script>
import axios from "axios";
import MarkdownViewer from "@/components/MarkdownViewer.vue";

export default {
  name: "ArticlePage",
  components: {
    MarkdownViewer,
  },
  data() {
    return {
      article: {},
      articleContent: "",
    };
  },
  async created() {
    const articleId = this.$route.params.id; // 从路由参数中获取文章 ID
    await this.fetchArticle(articleId);
  },
  methods: {
    async fetchArticle(id) {
      try {
        const response = await axios.get(`https://vdslivestream-servicesedge.hydun.com/article/${id}`);
        this.article = response.data.data;
        this.articleContent = response.data.data.content; // 获取 Markdown 内容
      } catch (error) {
        console.error("Error fetching article:", error.message);
        this.$router.push("/404"); // 跳转到 404 页面
      }
    },
    shareArticle() {
      const shareData = {
        title: this.article.title,
        text: `快来看这篇文章：${this.article.title}`,
        url: window.location.href,
      };
      if (navigator.share) {
        navigator.share(shareData).catch((err) => {
          console.error("分享失败:", err.message);
        });
      } else {
        alert("您的浏览器不支持分享功能");
      }
    },
  },
};
</script>

<style scoped>
.article-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh; /* 占满整个视口 */
  padding: 20px; /* 增加内边距 */
}

.article-card {
  width: 60%; /* 默认宽度 */
  max-width: 800px; /* 最大宽度 */
  background: rgb(30, 30, 30); /* 卡片背景色 */
  border-radius: 12px; /* 圆角效果 */
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1); /* 添加阴影效果 */
  padding: 20px; /* 卡片内容的内边距 */
  overflow: hidden; /* 隐藏溢出内容 */
}

.article-header {
  text-align: center;
  margin-bottom: 20px;
}

.article-title {
  font-size: 2.5rem;
  font-weight: bold;
  margin: 20px 0;
  color: #efefef; /* 标题颜色 */
}

.loading {
  text-align: center;
  font-size: 1.5rem;
  color: gray;
  margin: 20px 0;
}

.article-footer {
  margin-top: 20px; /* 与内容保持间距 */
}

/* 按钮样式 */
.mdui-button {
  width: 100%; /* 按钮占满宽度 */
  background-color: #007bff; /* 按钮背景色 */
  color: white;
  border-radius: 8px;
  text-transform: none; /* 禁用文本大写 */
}

/* 小屏设备样式 */
@media (max-width: 768px) {
  .article-card {
    width: 90%; /* 小屏设备宽度为 90% */
    max-width: none; /* 移除最大宽度限制 */
    padding: 15px; /* 减小内边距 */
  }

  .article-title {
    font-size: 1.8rem; /* 减小标题字体大小 */
  }
}
</style>