<template>
    <div class="markdown-viewer">
      <div v-for="(block, index) in contentBlocks" :key="index">
        <!-- 渲染 Markdown 文本块 -->
        <div v-if="block.type === 'markdown'" v-html="block.content"></div>
  
        <!-- 渲染直播间卡片块 -->
        <div v-else-if="block.type === 'liveRoomCards'">
          <!-- 添加小灰字 -->
          <div class="linked-rooms-text">已链接直播间：</div>
          <div class="live-rooms-container shadow-container" @wheel="handleWheel">
            <div class="cards-container">
              <LiveRoomCard
                v-for="(room, index) in block.rooms"
                :key="room.room_id"
                :room="room"
                :position="getPosition(index, block.rooms.length)"
                @preview-activated="onPreviewActivated"
                @preview-deactivated="onPreviewDeactivated"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>  

<script>
import { marked } from "marked";
import axios from "axios";
import LiveRoomCard from "@/components/LiveRoomCard.vue";

export default {
  name: "MarkdownViewer",
  components: {
    LiveRoomCard,
  },
  props: {
    content: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      contentBlocks: [],  // 存储解析后的内容块数组
      isPreviewing: false,
    };
  },
  async created() {
    await this.processContent(this.content);
  },
  methods: {
    async processContent(markdown) {
      const linkroomRegex = /<!vdslivestream-linkroom>(.*?)<\/!vdslivestream-linkroom>/g;

      let lastIndex = 0;
      let match;
      const blocks = [];

      while ((match = linkroomRegex.exec(markdown)) !== null) {
        // 提取前面的 Markdown 文本
        if (match.index > lastIndex) {
          const text = markdown.slice(lastIndex, match.index);
          blocks.push({
            type: 'markdown',
            content: marked(text, { breaks: true }),
          });
        }

        // 提取直播间 ID
        const roomIds = match[1].split(',').map((id) => id.trim());
        const rooms = await this.fetchRoomDetails(roomIds);

        blocks.push({
          type: 'liveRoomCards',
          rooms: rooms,
        });

        lastIndex = linkroomRegex.lastIndex;
      }

      // 添加剩余的 Markdown 文本
      if (lastIndex < markdown.length) {
        const text = markdown.slice(lastIndex);
        blocks.push({
          type: 'markdown',
          content: marked(text, { breaks: true }),
        });
      }

      this.contentBlocks = blocks;
    },
    async fetchRoomDetails(roomIds) {
      try {
        const roomDetails = await Promise.all(
          roomIds.map((roomId) =>
            axios
              .get(`https://static.vdsentnet.com/lsapi/search/room/?term=${roomId}`)
              .then((res) => res.data[0])
          )
        );
        return roomDetails;
      } catch (error) {
        console.error("Error fetching room details:", error);
        return [];
      }
    },
    getPosition(index, length) {
      if (index === 0) return "first";
      if (index === length - 1) return "last";
      return "middle";
    },
    onPreviewActivated() {
      this.isPreviewing = true;
    },
    onPreviewDeactivated() {
      this.isPreviewing = false;
    },
    handleWheel(event) {
      const container = event.currentTarget;
      const delta = Math.sign(event.deltaY) * 300;

      container.scrollBy({ left: delta, behavior: 'smooth' });
      event.preventDefault();
    },
  },
};
</script>


<style scoped>
.markdown-viewer {
  font-family: Arial, sans-serif;
  line-height: 1.6;
  color: #efefef;
}

/* 添加小灰字的样式 */
.linked-rooms-text {
  font-size: 14px;
  color: #aaa; /* 灰色字体 */
  margin-bottom: 5px; /* 与卡片保持间距 */
}

/* 直播间卡片的容器样式，与首页类似 */
.live-rooms-container {
  display: flex;
  gap: 10px;
  overflow-x: auto;
  position: relative;
  min-height: 100px;
  height: auto;
  white-space: nowrap;
  scroll-snap-type: x mandatory;
}

.cards-container {
  display: flex;
  gap: 10px;
  overflow: visible;
  position: relative;
  transition: all 0.3s ease;
}

.cards-container > * {
  scroll-snap-align: start;
}

.cards-container.darkened .card-wrapper:not(.previewing) {
  filter: brightness(50%);
  transition: filter 0.3s ease;
}

/* 根据需要添加更多样式 */
</style>