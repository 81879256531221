<template>
  <div class="about-container">
    <img src="@/assets/vds-livestream-small-white.png" style="height: 55px;">
    <h1>轻松看直播，处处都精彩。</h1>
    <p style="text-align: center; margin-top: 25px;">版本: 1.7.2</p>
    <p style="text-align: center; margin-top: -15px;">构建版本: VER.LS.CH.241109.001 在2024-11-09 21:51:43 创建的production副本。</p>
    <p style="text-align: center;">发布说明：放弃2.0大版本更新计划，</p>

    <div style="text-align: center;color: gray;font-size: small;">
      Copyright ©️ 2023-Now Nanjing Weidi Aisi Cluture Media Co., Ltd. All Rights Reserved.
    </div>
    <div style="text-align: center; color: gray;font-size: small;">
      VDS Developer Team with Vue 3
    </div>
    <div style="text-align: center; color: gray;font-size: medium;font-size: x-small;">
      VDS智慧云端 提供计算支持
    </div>
  </div>
</template>

<script>
export default {
  name: 'AboutPage',
};
</script>

<style scoped>
.about-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%; /* 让容器高度占满整个页面 */
  text-align: center; /* 确保所有文本都居中 */
  margin: 0; /* 消除默认的边距 */
}
</style>