<template>
    <div style="overflow: auto ; text-align: center;">
        <img src="@/assets/vds-livestream-small-white.png"
            style="height: 70px; margin-top: 80px;"><mdui-badge>App</mdui-badge>
        <h1>轻松看直播，处处都精彩</h1>
        <!-- <p style="color: red;">在我们发布下一个新版本前，App暂不可用。</p> -->
        <mdui-button href="https://vds-img-1301106215.cos.ap-nanjing.myqcloud.com/app-opt/LSCH240921001.apk">下载 Android 版</mdui-button>
        <mdui-button style="margin-left: 10px;" href="https://vds-img-1301106215.cos.ap-nanjing.myqcloud.com/app-opt/LSCH240921001.ipa">下载 iOS 版（需要自行签名）</mdui-button>
        <br />
        <!-- <img src="/pb-image/Windows-logo.png" style="width: 20px; height: 20px; margin-top: 15px;"><img -->
            <!-- src="/pb-image/apple-logo.png" style="width: 20px; height: 20px; margin-left: 5px;"> -->
        <p style="color: gray; font-size: small;">v1.7.1 (Android / Apple iOS) | 正式版 | VER.LS1.CH.241015.001</p>
        <div style="text-align: center; margin-top: 50px;color: gray;font-size: small;">基于 Flutter 技术架构</div>
        <div style="text-align: center; color: gray;font-size: medium;font-size: x-small;">VDS智慧云端 提供计算支持</div>
    </div>
</template>

<script>
export default {
    name: "DownloadPage"
}
</script>