<template>
  <div>
    <!-- 如果屏幕宽度大于600px，显示导航 rail -->
    <mdui-navigation-rail
      v-if="isDesktop"
      :value="currentValue"
      class="navigation-rail-container"
    >
      <mdui-navigation-rail-item icon="home" value="Home" @click="navigateTo('Home')">主页</mdui-navigation-rail-item>
      <mdui-navigation-rail-item icon="tv" value="All" @click="navigateTo('All')">全部</mdui-navigation-rail-item>
      <mdui-navigation-rail-item icon="download" value="Download" @click="navigateTo('DownloadPage')">下载</mdui-navigation-rail-item>
      <mdui-navigation-rail-item
        v-if="isAnchorPortalAllowed"
        icon="live_tv"
        value="AnchorPortal"
        @click="openAnchorPortal"
      >
        主播门户
      </mdui-navigation-rail-item>
    </mdui-navigation-rail>

    <!-- 如果屏幕宽度小于600px，显示底部导航栏 -->
    <mdui-navigation-bar v-else :value="currentValue" class="navigation-bar-container">
      <mdui-navigation-bar-item icon="home" value="Home" @click="navigateTo('Home')">主页</mdui-navigation-bar-item>
      <mdui-navigation-bar-item icon="tv" value="All" @click="navigateTo('All')">全部</mdui-navigation-bar-item>
      <mdui-navigation-bar-item icon="download" value="Download" @click="navigateTo('DownloadPage')">下载</mdui-navigation-bar-item>
      <mdui-navigation-bar-item
        v-if="isAnchorPortalAllowed"
        icon="live_tv"
        value="AnchorPortal"
        @click="openAnchorPortal"
      >
        主播门户
      </mdui-navigation-bar-item>
    </mdui-navigation-bar>
  </div>
</template>

<script>
import { ref, watch, onMounted, onBeforeUnmount } from 'vue';
import axios from 'axios';
import { useRouter, useRoute } from 'vue-router';

export default {
  name: 'NavigationRail',
  setup() {
    const router = useRouter();
    const route = useRoute();
    
    const currentValue = ref(route.name);
    const isDesktop = ref(window.innerWidth > 600); // 定义是否为桌面端
    const isAnchorPortalAllowed = ref(false); // 定义主播门户的权限

    // 监听屏幕尺寸变化
    const handleResize = () => {
      isDesktop.value = window.innerWidth > 600;
    };

    const navigateTo = (routeName) => {
      router.push({ name: routeName });
    };

    // 打开主播门户并发出全局通知
    const openAnchorPortal = () => {
      router.push({ path: '/anchorportal' }); // 导航到主播门户路径
      window.dispatchEvent(new Event('anchorPortalOpened')); // 发送全局事件通知
    };

    // 查询主播门户权限
    const checkAccess = async () => {
      const token = localStorage.getItem('token');
      if (!token) {
        isAnchorPortalAllowed.value = false; // 如果没有token，隐藏主播门户
        return; 
      }

      try {
        const response = await axios.get('https://1.13.182.201:5688/myaccess/allowmyaccess', {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        if (response.data.access === 'yes') {
          isAnchorPortalAllowed.value = true; // 如果有权限，显示主播门户
        } else {
          isAnchorPortalAllowed.value = false; // 如果没有权限，隐藏主播门户
        }
      } catch (error) {
        console.error('查询主播门户权限失败:', error);
        isAnchorPortalAllowed.value = false;
      }
    };

    watch(
      () => route.name,
      (newName) => {
        currentValue.value = newName;
      }
    );

    onMounted(() => {
      // 页面加载时设置初始状态
      window.addEventListener('resize', handleResize);
      checkAccess(); // 加载时检查权限

      // 监听登录状态变化
      window.addEventListener('loginStateChange', checkAccess);
    });

    onBeforeUnmount(() => {
      // 移除监听器
      window.removeEventListener('resize', handleResize);
      window.removeEventListener('loginStateChange', checkAccess);
    });

    return {
      currentValue,
      navigateTo,
      openAnchorPortal, // 点击主播门户时触发
      isDesktop,
      isAnchorPortalAllowed, // 导航栏中是否显示"主播门户"
    };
  },
};
</script>

<style scoped>
.navigation-rail-container {
  position: fixed;
  top: 70px;
}

.navigation-bar-container {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
}
</style>