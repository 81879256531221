<template>
  <div class="livepage">
    <div class="simple-info-top">
      <div class="shadow-container info-channelinfo" id="info-channelinfo" style="display: flex; align-items: center;">
        <!-- 绑定 imageUrl 到 src 属性 -->
        <img :src="imageUrl" style="width: 70px; margin-right: 10px;">
        <h2 style="margin: 0;">频道直播</h2>
        <img src="https://ls.vds.pub/img/IPAddr.svg" style="width: 15px;margin-left: 5px">
        <div id="ipregion" style="color: #3E3A39; margin-left: 3px">{{ ipRegion }}</div>
        <img v-if="mcnName" id="mcninfo" src="https://ls.vds.pub/img/MCN.svg" style="width: 15px;margin-left: 5px">
        <a v-if="mcnName" :href="`/space/mcn/${mcnId}`" style="color: #3E3A39; margin-left: 3px">{{ mcnName }}</a>
      </div>
    </div>
    <!-- 直播页面内容 -->
    <div id="video-chat-container">
      <!-- 视频容器 -->
      <div id="video-container">
        <AuroraPlayer v-if="showPlayer" :options="playerOptions" />
        <div id="placeholder" v-else>
          <h1>直播未开始</h1>
          <p>看来 我们的直播消失了。我们应该去哪里找到它？</p>
        </div>
      </div>

      <!-- 聊天容器 -->
      <div id="chat-container">
        <!-- 聊天容器的标题和在线人数 -->
        <div id="chat-header">
          <h2 style="margin-left: 15px;margin-bottom: 0;">实时聊天</h2>
          <div id="onlineUsersCountWrapper">
            <div id="onlineUsersDot"></div>
            <span id="onlineUsersCount">{{ onlineUsersText }}</span>
          </div>
          <div style="margin-bottom: 15px"></div>
        </div>
        <div v-if="isChatVisible" id="chat-messages-container">
          <div id="chat-messages"></div>
        </div>
        <div id="chat-closed-message-container" v-else>
          <h2>直播已关闭实时聊天。</h2>
        </div>
        <mdui-text-field :label="isChatVisible ? (isLoggedIn ? '在实时聊天中讨论' : '登录以发送消息') : '实时聊天已停用'"
          :disabled="!isLoggedIn || !isChatVisible" id="chat-input" variant="filled" type="text" name=""
          @keyup.enter="sendMessage">
          <!-- eslint-disable-next-line vue/no-deprecated-slot-attribute -->
          <mdui-button-icon slot="end-icon" icon="send" id="sendsms" @click="sendMessage" type="button"
            variant="standard"></mdui-button-icon>
          <!-- eslint-disable-next-line vue/no-deprecated-slot-attribute -->
          <mdui-button-icon slot="icon" icon="image" id="sendimg" @click="selectImage" type="button" variant="standard"
            :disabled="isSendingImage" :loading="isSendingImage"></mdui-button-icon>
        </mdui-text-field>

        <!-- 隐藏的文件选择器 -->
        <input type="file" ref="fileInput" accept="image/*" style="display: none" @change="handleImageUpload">
      </div>
    </div>

    <div id="info" style="margin-top: 20px;">
      <div class="shadow-container info-channelinfo" id="info-channelinfo" style="display: flex; align-items: center;">
        <!-- 绑定 imageUrl 到 src 属性 -->
        <img :src="imageUrl" style="width: 70px; margin-right: 10px;">
        <h2 style="margin: 0;">频道直播</h2>
        <img src="https://ls.vds.pub/img/IPAddr.svg" style="width: 15px;margin-left: 5px">
        <div id="ipregion" style="color: #3E3A39; margin-left: 3px">{{ ipRegion }}</div>
        <img v-if="mcnName" id="mcninfo" src="https://ls.vds.pub/img/MCN.svg" style="width: 15px;margin-left: 5px">
        <a v-if="mcnName" :href="`/space/mcn/${mcnId}`" style="color: #3E3A39; margin-left: 3px">{{ mcnName }}</a>
      </div>

      <!-- EPG 时间轴 -->
      <mdui-card class="time-axis" variant="filled" id="epg-container" v-show="!epgHidden">
        <div class="label-early">更早</div>
        <mdui-card class="epg-card" id="previous-program">
          <div class="loading">加载中...</div>
        </mdui-card>
        <mdui-card class="epg-card" id="current-program">
          <div class="loading">加载中...</div>
        </mdui-card>
        <mdui-card class="epg-card" id="next-program">
          <div class="loading">加载中...</div>
        </mdui-card>
        <div class="label-late">更晚</div>
      </mdui-card>

      <div v-if="showOptions" id="options">
        <p class="options-text">你可以查看该直播的：</p>
        <mdui-button @click="insertM3U8DialogScript">直播源m3u8地址（原画）
          <!-- eslint-disable-next-line vue/no-deprecated-slot-attribute -->
          <mdui-icon slot="icon" name="attach_file"></mdui-icon>
        </mdui-button>
      </div>
    </div>

    <!-- M3U8对话框内容 -->
    <mdui-dialog class="m3u8Dialog" close-on-overlay-click>
      <!-- eslint-disable-next-line vue/no-deprecated-slot-attribute -->
      <span slot="headline">谢谢！</span>
      <!-- eslint-disable-next-line vue/no-deprecated-slot-attribute -->
      <span slot="description">
        请你在任何引用的位置注明出处来自
        <a href="http://ls.vds.pub" target="_blank">ls.vds.pub</a>。<br />
        这些直播源的版权归相关主播或版权方所有。<br />
        欢迎你分享和使用这些资源，但希望你尊重版权并遵循主播和平台要求的使用规定。<br />
        谢谢支持。<br />
        本直播的直播源地址是：{{ playerUrl }}
      </span>
    </mdui-dialog>

    <!-- 互动对话框内容 -->
    <mdui-dialog class="InteractionDialog" close-on-overlay-click>
      <!-- eslint-disable-next-line vue/no-deprecated-slot-attribute -->
      <span slot="headline">来自直播的提示</span>
      <!-- eslint-disable-next-line vue/no-deprecated-slot-attribute -->
      <span slot="description">
        {{ InteractionMessage }}
      </span>
    </mdui-dialog>
  </div>
</template>

<script>
import { ref, onMounted, computed, onBeforeUnmount } from 'vue';
import io from 'socket.io-client';
import axios from 'axios';
import Compressor from 'compressorjs'; // 使用 compressor.js 进行图片压缩
import AuroraPlayer from '@/components/AuroraPlayerInsert.vue';

export default {
  name: 'RoomPage',
  components: {
    AuroraPlayer,
  },

  setup() {
    const roomId = ref(null);
    const showPlayer = ref(false);
    const playerOptions = ref({});
    const onlineUsers = ref(0);
    const liveStatus = ref('off');
    const imageUrl = ref('');
    const ipRegion = ref('正在获取...');
    const isChatVisible = ref(true);
    const isLoggedIn = ref(false);
    const showOptions = ref(false);
    const playerUrl = ref('');
    const InteractionMessage = ref('');
    const epgHidden = ref(true);
    const currentEndTime = ref(null);
    const mcnName = ref(null);
    const mcnId = ref(null);
    const isSendingImage = ref(false);

    let firstValidIp = '';
    let firstValidImageUrl = '';
    let socket;
    let heartbeatInterval;
    let hideChatInitialized = false;
    let hideOptionsInitialized = false;
    let playerUrlInitialized = false;
    let timerInterval = null;

    // 动态调整聊天容器的高度
    const adjustChatHeight = () => {
      // 只有当屏幕宽度小于 600px 时才执行高度调整
      if (window.innerWidth <= 600) {
        const videoContainer = document.getElementById('video-container');
        const chatContainer = document.getElementById('chat-container');

        if (videoContainer && chatContainer) {
          const videoHeight = videoContainer.offsetWidth / 16 * 9; // 16:9 高度
          const windowHeight = window.innerHeight;
          const remainingHeight = windowHeight - videoHeight;

          // 减少10%的高度
          const adjustedHeight = remainingHeight * 0.66;

          chatContainer.style.height = `${adjustedHeight}px`;
        }
      } else {
        // 如果屏幕宽度大于600px，则恢复默认样式
        const chatContainer = document.getElementById('chat-container');
        if (chatContainer) {
          chatContainer.style.height = 'auto'; // 恢复默认高度
        }
      }
    };

    // 在页面加载时和窗口大小变化时调整高度
    onMounted(() => {
      adjustChatHeight();
      window.addEventListener('resize', adjustChatHeight);
    });

    // 移除事件监听器
    onBeforeUnmount(() => {
      window.removeEventListener('resize', adjustChatHeight);
    });

    // 选择图片
    const selectImage = () => {
      const fileInput = document.createElement('input');
      fileInput.type = 'file';
      fileInput.accept = 'image/*';
      fileInput.click();
      fileInput.onchange = (event) => {
        const file = event.target.files[0];
        if (file) {
          compressAndUploadImage(file);
        }
      };
    };

    // 压缩并上传图片
    const compressAndUploadImage = (file) => {
      isSendingImage.value = true; // 显示加载状态

      // 使用 Compressor.js 压缩图片
      new Compressor(file, {
        quality: 0.6, // 压缩质量
        maxWidth: 500, // 设置最大宽度为 500 像素
        success(result) {
          // 将压缩后的图片上传
          const formData = new FormData();
          formData.append('image', result);

          axios.post('https://vdslivestream-servicesedge.hydun.com/upload-image', formData, {
            headers: {
              'Content-Type': 'multipart/form-data',
              Authorization: `Bearer ${localStorage.getItem('token')}`,
            },
          })
            .then(response => {
              if (response.data.success) {
                // 将图片地址作为消息发送
                sendImageMessage(response.data.imageUrl);
              } else {
                alert('图片上传失败');
              }
            })
            .catch(() => {
              alert('图片上传失败');
            })
            .finally(() => {
              isSendingImage.value = false; // 关闭加载状态
            });
        },
        error(err) {
          console.error('图片压缩失败:', err);
          isSendingImage.value = false;
        },
      });
    };

    const fetchMCNInfo = async () => {
      try {
        const response = await axios.get(`https://static.vdsentnet.com/lsapi/room_to_mcn/?roomid=${roomId.value}`);
        const data = response.data;

        if (data.mcn_name && data.mcn_id) {
          mcnName.value = data.mcn_name;
          mcnId.value = data.mcn_id;
        } else {
          mcnName.value = null;
          mcnId.value = null;
        }
      } catch (error) {
        console.error('Error fetching MCN data:', error);
        mcnName.value = null;
        mcnId.value = null;
      }
    };

    const hideEPG = () => {
      epgHidden.value = true;
    };

    const fetchEPG = async () => {
      axios.get(`https://static.vdsentnet.com/lsapi/fetch_epg/?roomid=${roomId.value}`)
        .then(response => {
          const data = response.data;
          if (data.error || !data.current_programme) {
            throw new Error('Invalid EPG data or error in response');
          }
          currentEndTime.value = data.current_programme.end_time;
          updateEPG(data.previous_programme, data.current_programme, data.next_programme);

          // 显示 EPG 容器
          epgHidden.value = false;

          setTimeout(fetchEPG, (currentEndTime.value - Math.floor(Date.now() / 1000) + 1) * 1000);
        })
        .catch(error => {
          console.error('Error fetching EPG data:', error);
          hideEPG();
        });
    };

    const updateEPG = (previous, current, next) => {
      updateCard(document.getElementById('previous-program'), previous);
      updateCard(document.getElementById('current-program'), current, true);
      updateCard(document.getElementById('next-program'), next, false);
      startTimer(currentEndTime.value);
    };

    const updateCard = (card, program, showDot) => {
      if (program) {
        card.innerHTML = `
      <div class="epg-card-content">
        <p class="epg-title">${showDot ? '<span class="dotradis"></span>' : ''}${program.name}</p>
        <p class="epg-description">${program.description}</p>
        <div class="epg-time">
          <svg t="1724521464307" class="clock-icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="4334"><path d="M512 128c-211.744 0-384 172.256-384 384s172.256 384 384 384 384-172.256 384-384-172.256-384-384-384z m0 704c-176.448 0-320-143.552-320-320s143.552-320 320-320 320 143.552 320 320-143.552 320-320 320z" fill="" p-id="4335"></path><path d="M660.736 606.112L528 529.344V320c0-17.664-14.336-32-32-32s-32 14.336-32 32v224c0 11.872 14.752 21.824 24.352 27.328 2.656 4 9.952 7.616 14.4 10.176l132 80c15.296 8.832 31.872 3.584 40.704-11.712s0.608-34.848-14.72-43.68z"></path></svg>
          <p>${new Date(program.start_time * 1000).toLocaleTimeString()} - ${new Date(program.end_time * 1000).toLocaleTimeString()}</p>
          ${showDot ? '<p class="remaining-time"></p>' : ''}
        </div>
      </div>
    `;
      } else {
        card.innerHTML = `
    <div class="epg-card-content">
    <div class="epg-title">无信息</div>
    </div>`;
      }
    };

    const startTimer = (endTime) => {
      const remainingTimeElement = document.querySelector('.remaining-time');
      if (!remainingTimeElement) return;

      const updateRemainingTime = () => {
        const now = Math.floor(Date.now() / 1000);
        let remainingSeconds = endTime - now;
        if (remainingSeconds <= 0) {
          remainingTimeElement.textContent = '已结束';
          clearInterval(timerInterval);
          return;
        }

        const hours = Math.floor(remainingSeconds / 3600);
        const minutes = Math.floor((remainingSeconds % 3600) / 60);
        const seconds = remainingSeconds % 60;

        let remainingTimeText = '';
        if (hours > 0) {
          remainingTimeText += `${hours}时`;
        }
        remainingTimeText += `${minutes}分${seconds}秒`;

        remainingTimeElement.textContent = `${remainingTimeText}后结束`;
      };

      clearInterval(timerInterval);
      updateRemainingTime();
      timerInterval = setInterval(updateRemainingTime, 1000);
    };

    const insertM3U8DialogScript = () => {
      const script = document.createElement('script');
      script.id = 'dialog-opening';
      script.innerHTML = `
        (() => {
          const dialog = document.querySelector(".m3u8Dialog");
          dialog.open = true;
          setTimeout(() => {
            delete dialog;
          }, 300);
        })();
      `;
      document.body.appendChild(script);

      setTimeout(() => {
        script.remove();
      }, 500);
    };

    const onlineUsersText = computed(() => {
      return liveStatus.value === 'on'
        ? `${onlineUsers.value} 人正在观看`
        : `${onlineUsers.value} 人正在等待`;
    });

    const getIPRegion = async (ip) => {
      try {
        const response = await axios.get(`https://searchplugin.csdn.net/api/v1/ip/get?ip=${ip}`);
        const address = response.data.data.address;
        const province = address.split(' ')[1];
        ipRegion.value = province || '未知';
      } catch (error) {
        console.error('Error fetching IP region:', error);
        ipRegion.value = '未知';
      }
    };

    const checkLoginStatus = async () => {
      const token = localStorage.getItem('token');
      if (token) {
        try {
          const response = await axios.get('https://vdslivestream-servicesedge.hydun.com/user/info', {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
          isLoggedIn.value = response.data.success;
        } catch (error) {
          console.error('Error checking login status:', error);
          isLoggedIn.value = false;
        }
      } else {
        isLoggedIn.value = false;
      }
    };

    const sendMessage = async (content = '', messageType = 'MESSAGE') => {
      if (!isLoggedIn.value) {
        alert('请先登录再发送消息');
        return;
      }

      const inputElement = document.querySelector('#chat-input');
      const messageContent = messageType === 'MESSAGE' ? inputElement.value.trim() : content;

      if (messageContent) {
        try {
          const response = await axios.get('https://vdslivestream-servicesedge.hydun.com/user/info', {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('token')}`,
            },
          });

          if (response.data.success) {
            let displayName = response.data.user.nickname;
            if (!displayName) {
              displayName = response.data.user.UDID || response.data.user.username;
            }

            socket.emit('send_message', {
              roomId: roomId.value,
              content: messageContent,
              token: localStorage.getItem('token'),
              nickname: displayName,
              messageType: messageType, // 这里指定消息类型
            });

            if (messageType === 'MESSAGE') {
              inputElement.value = ''; // 清空输入框
            }
          } else {
            console.error('无法获取用户信息');
          }
        } catch (error) {
          console.error('获取用户信息失败:', error);
        }
      }
    };

    const sendImageMessage = (imageUrl) => {
      // 调用 sendMessage 方法，传递图片 URL 和消息类型为 'IMAGE'
      sendMessage(imageUrl, 'IMAGE');
    };

    const setupWebSocket = () => {
      console.log(`Connecting to room with ID: ${roomId.value}`);

      socket = io('https://vdslivestream-servicesedge.hydun.com', {
        path: '/vdsls-sys-endpoint',
      });

      socket.on('connect', () => {
        console.log('WebSocket connected');
        socket.emit('request_room_data', roomId.value);
        startHeartbeat();
      });

      socket.on('receive_interaction', (interaction) => {
        console.log('Received interaction message:', interaction);

        const interactionTime = new Date(interaction.created_at).getTime();
        const currentTime = Date.now();

        if (currentTime - interactionTime <= 3000) {
          console.log('接受互动:', interaction);

          InteractionMessage.value = interaction.message;

          const script = document.createElement('script');
          script.id = 'dialog-opening';
          script.innerHTML = `
            (() => {
              const dialog = document.querySelector(".InteractionDialog");
              dialog.open = true;
              setTimeout(() => {
                delete dialog;
              }, 300);
            })();
          `;
          document.body.appendChild(script);

          setTimeout(() => {
            script.remove();
          }, 500);
        } else {
          console.log('互动已超时');
        }
      });

      socket.on('room_not_found', () => {
        console.error('Room not found');
        window.location.href = '/err/404';
      });

      socket.on('live_status', async (data) => {
        console.log('Live status received:', data);

        if (!hideChatInitialized) {
          hideChatInitialized = true;
          isChatVisible.value = data.hide_chat !== 'on';
        }

        if (!hideOptionsInitialized) {
          hideOptionsInitialized = true;
          showOptions.value = data.hide_m3u8address !== 'on';
        }

        if (!playerUrlInitialized) {
          playerUrlInitialized = true;
          playerUrl.value = data.player_url;
        }

        if (data.latest_ip && !firstValidIp) {
          firstValidIp = data.latest_ip;
          await getIPRegion(firstValidIp);
        }

        if (data.image_url && !firstValidImageUrl) {
          firstValidImageUrl = data.image_url;
          imageUrl.value = firstValidImageUrl;
        }

        liveStatus.value = data.status;

        if (data.status === 'on') {
  const qualityUrls = [];

  // 检查 player_url 是否存在且包含 "VDSLSLU"
  if (data.player_url && data.player_url.includes('VDSLSLU')) {
    const baseUrl = data.player_url.replace('_VDSLSLU.m3u8', '');
    qualityUrls.push({ name: '自适应画质', url: `${baseUrl}_VDSLSLU.m3u8` });
    qualityUrls.push({ name: '原画 Original', url: `${baseUrl}.m3u8` });
    qualityUrls.push({ name: '超清 2160P', url: `${baseUrl}_2160uhd.m3u8` });
    qualityUrls.push({ name: '高清+ 1080P', url: `${baseUrl}_1080hdplus.m3u8` });
    qualityUrls.push({ name: '高清 1080P', url: `${baseUrl}_1080hd.m3u8` });
    qualityUrls.push({ name: '标准 720P', url: `${baseUrl}_720normal.m3u8` });
    qualityUrls.push({ name: '流畅 480P', url: `${baseUrl}_480sd.m3u8` });

    playerOptions.value = {
      live: true,
      autoplay: true,
      video: {
        quality: qualityUrls,
        defaultQuality: 0,
      },
    };
  } else {
    // 如果没有特定的 player_url，则直接使用 URL 进行播放
    playerOptions.value = {
      live: true,
      autoplay: true,
      video: {
        url: data.player_url || data.playerUrl, // 默认链接或根据情况提供备选链接
        type: 'hls',
      },
    };
  }

  showPlayer.value = true;

  } else if (data.status === 'off') {
    // 当直播结束时，隐藏播放器
    showPlayer.value = false;
    liveStatus.value = 'off';
    console.log('直播结束，关闭播放器');
  }
      });

      socket.on('receive_message', (message) => {
        if (isChatVisible.value) {
          console.log('Received message:', message);
          insertMessageWithAnimation(message, false);
        }
      });

      socket.on('receive_messages', (messages) => {
        if (isChatVisible.value) {
          console.log('Received multiple messages:', messages);
          messages.forEach((msg) => insertMessageWithAnimation(msg, true));
        }
      });

      socket.on('update_client_count', (count) => {
        console.log('Online users count received:', count);
        onlineUsers.value = count;
      });

      const insertMessageWithAnimation = (message, noAnimation) => {
        if (!isChatVisible.value) return;

        const chatMessagesContainer = document.getElementById('chat-messages');
        const messageElement = document.createElement('div');

        if (message.messageType === 'INFO') {
          messageElement.classList.add('info-message');
          messageElement.innerHTML = `
            <div class="card-messages" style="background-color: #e6a800; color: #fff8e6; padding: 10px; border-radius: 5px;">
              <div style="display: flex; align-items: center; margin-bottom: 5px;">
                <img src="data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath fill='%23fff8e6' fill-rule='evenodd' d='M8.893 1.5c-.183-.31-.52-.5-.887-.5s-.703.19-.886.5L.138 13.499a.98.98 0 0 0 0 1.001c.193.31.53.501.886.501h13.964c.367 0 .704-.19.877-.5a1.03 1.03 0 0 0 .01-1.002L8.893 1.5zm.133 11.497H6.987v-2.003h2.039v2.003zm0-3.004H6.987V5.987h2.039v4.006z'/%3E%3C/svg%3E" style="width: 16px; height: 16px; margin-right: 8px;" />
                <span style="font-weight: bold; color: #fff8e6;">注意</span>
              </div>
              <div style="color: #fff; font-size: 12px;">
                <span style="font-weight: bold; margin-right: 5px;">${message.username}</span>
                ${message.content}
              </div>
            </div>`;
        } else if (message.messageType === 'WARN') {
          messageElement.innerHTML = `
            <div class="card-messages" style="background-color: #ff4d4d; color: #fff; padding: 10px; border-radius: 5px;">
              <div  style="display: flex; align-items: center; margin-bottom: 5px;">
                <img src="data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath fill='%23fff' fill-rule='evenodd' d='M8.893 1.5c-.183-.31-.52-.5-.887-.5s-.703.19-.886.5L.138 13.499a.98.98 0 0 0 0 1.001c.193.31.53.501.886.501h13.964c.367 0 .704-.19.877-.5a1.03 1.03 0 0 0 .01-1.002L8.893 1.5zm.133 11.497H6.987v-2.003h2.039v2.003zm0-3.004H6.987V5.987h2.039v4.006z'/%3E%3C/svg%3E" style="width: 16px; height: 16px; margin-right: 8px;" />
                <span style="font-weight: bold; color: #fff;">警告</span>
              </div>
              <div style="color: #fff; font-size: 12px;">
                <span style="font-weight: bold; margin-right: 5px;">${message.username}</span>
                ${message.content}
              </div>
            </div>`;
        } else if (message.messageType === 'IMAGE') {
          messageElement.classList.add('chat-message');
          messageElement.innerHTML = `
            <div style="margin-bottom: 10px;">
              <span class="username" style="margin-right: 5px; color: rgba(164, 164, 164, 1); font-size: 13px;">${message.username}</span>
              <img src="${message.content}" alt="Image" style="width: 100px; height: auto; display: block;" />
            </div>`;
        } else {
          messageElement.classList.add('chat-message');
          const badge = message.userType === 'SYSTEM-ADMIN' ? `<span class="admin-badge">超管</span>` : '';

          messageElement.innerHTML = `
            <span style="font-size: 13px;">
              <span class="username" style="margin-right: 5px; color: rgba(164, 164, 164, 1);">
                ${message.username}${badge}
              </span>
              ${message.content}
            </span>`;
        }

        if (noAnimation) {
          chatMessagesContainer.insertBefore(messageElement, chatMessagesContainer.firstChild);
        } else {
          messageElement.style.opacity = 0;
          chatMessagesContainer.appendChild(messageElement);

          setTimeout(() => {
            messageElement.style.transition = 'opacity 0.2s';
            messageElement.style.opacity = 1;
          }, 0);

          chatMessagesContainer.scrollTop = chatMessagesContainer.scrollHeight;
        }
      };

      const startHeartbeat = () => {
        heartbeatInterval = setInterval(() => {
          if (roomId.value) {
            socket.emit('heartbeat', roomId.value);
          }
        }, 2000);
      };
    };

    const stopScripts = () => {
      if (socket) {
        socket.disconnect();
        console.log('WebSocket disconnected');
      }

      if (heartbeatInterval) {
        clearInterval(heartbeatInterval);
        console.log('Heartbeat interval cleared');
      }

      clearInterval(timerInterval);
    };

    onMounted(() => {
      roomId.value = window.location.pathname.split('/').pop();
      setupWebSocket();
      checkLoginStatus();
      fetchEPG();
      fetchMCNInfo();
    });

    onBeforeUnmount(() => {
      stopScripts();
    });

    return {
      showPlayer,
      playerOptions,
      sendMessage,
      selectImage,
      isSendingImage,
      onlineUsersText,
      imageUrl,
      ipRegion,
      isChatVisible,
      isLoggedIn,
      showOptions,
      insertM3U8DialogScript,
      playerUrl,
      InteractionMessage,
      epgHidden,
      mcnName,
      mcnId,
    };
  },
};
</script>

<style>
.epg-card-content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  padding: 10px;
}

.epg-title {
  font-weight: bold;
  margin: 0;
}

.epg-description {
  color: gray;
  font-size: 12px;
  margin: 0;
}

.epg-time {
  display: flex;
  align-items: center;
  color: gray;
  font-size: 12px;
  margin-top: 5px;
}

.clock-icon {
  width: 16px;
  height: 16px;
  margin-right: 5px;
}

.remaining-time {
  font-weight: bold;
  margin-left: 10px;
}

.dotradis {
  width: 8px;
  height: 8px;
  background-color: red;
  border-radius: 50%;
  display: inline-block;
  margin-right: 5px;
}

.admin-badge {
  background-color: #ffcc00;
  color: #333;
  padding: 2px 4px;
  border-radius: 3px;
  font-size: 10px;
  margin-left: 5px;
  font-weight: bold;
  vertical-align: middle;
}

.card-messages {
  margin-top: 10px;
}
</style>

<style scoped>
/* 直播信息容器 */
#info-accurateinfo {
  margin-top: -10px;
}

/* 直播主题标题 */
#info-accurateinfo h1 {
  font-size: 24px;
  margin-bottom: 20px;
}

.livepage {
  margin-top: 10px;
}

/* 创建者信息容器 */
.creator-container {
  display: flex;
  align-items: center;
  margin-top: -15px;
}

/* 创建者头像 */
.creator-avatar {
  margin-top: -15px;
  margin-right: 5px;
}

/* 创建者名称和徽章的容器 */
.creator-info {
  display: flex;
  align-items: center;
  margin-top: -15px;
}

/* 创建者徽章 */
.creator-badge {
  width: 15px;
  height: 15px;
  margin-left: 5px;
}

/* 订阅者信息 */
.subscriber {
  color: gray;
  margin-top: -35px;
}

/* 订阅按钮 */
.subscribe-button {
  margin-top: -15px;
  margin-left: 5px;
}

/* 直播选项文本 */
.options-text {
  color: gray;
  margin-bottom: 5px;
}

#video-chat-container {
  display: flex;
  height: 75vh;
}

#video-container {
  flex: 1;
}

#chat-container {
  width: 300px;
  height: 100%;
  background-color: rgba(50, 50, 50, 1);
  color: #ffffff;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  /* 确保padding不会影响高度 */
}

#chat-header {
  position: sticky;
  top: 0;
  background-color: rgb(22, 22, 22);
  z-index: 1;
  padding: 3px;
  /* 添加padding来让header更好看 */
  box-sizing: border-box;
  /* 确保padding不会影响布局 */
}

#chat-messages-container {
  flex: 1;
  /* 使其填满剩余空间 */
  overflow-y: auto;
  /* 允许垂直滚动 */
  padding: 15px;
  box-sizing: border-box;
  /* 确保padding不会影响高度 */
  display: flex;
  flex-direction: column-reverse;
  /* 消息从底部开始显示 */
}

#chat-closed-message-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  color: gray;
  background-color: rgba(50, 50, 50, 1);
}

#placeholder {
  height: 100%;
  background-color: rgba(0, 0, 0, 1);
  color: #ffffff;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  /* 确保padding不会影响高度 */
}

#placeholder h1,
#placeholder p {
  color: grey;
  margin: 0;
}

.chat-message {
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: 8px;
  /* 调整文字字号 */
  opacity: 0;
  transition: opacity 0.2s ease-in-out;
}

.info-message {
  margin-top: 10px;
  margin-bottom: 10px;
  opacity: 0;
  transition: opacity 0.2s ease-in-out;
}

.username {
  margin-right: 5px;
  color: rgba(164, 164, 164, 1);
  /* 灰色用户名 */
}

#chat-closed-message {
  color: gray;
  margin-top: 0;
}

#onlineUsersCountWrapper {
  display: inline-block;
  margin-left: 15px;
  font-size: 14px;
}

#onlineUsersDot {
  width: 8px;
  height: 8px;
  background-color: red;
  border-radius: 50%;
  display: inline-block;
  margin-right: 5px;
}

.time-axis {
  position: relative;
  width: 100%;
  height: 150px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 30px;
  margin-top: 10px;
}

.time-axis::before {
  content: '';
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: gray;
}

.label-early,
.label-late {
  color: gray;
}

.epg-card {
  width: 300px;
  height: 123px;
  text-align: left;
  padding: 10px;
}

.epg-card p {
  margin: 5px 0;
}

.epg-card .description {
  color: gray;
  font-size: 12px;
  margin-top: 5px;
}

.epg-card .time-remaining {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.loading {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  font-size: 16px;
  color: gray;
}

.dot {
  height: 5px;
  width: 5px;
  background-color: red;
  border-radius: 50%;
  display: inline-block;
  margin-right: 5px;
}

/* 为聊天输入框添加样式 */
#chat-input {
  padding-left: 15px;
  padding-right: 15px;
  padding-bottom: 15px;
}

.simple-info-top {
    display: none;
    margin-bottom: 10px;
  }

/* 移动端布局调整 */
@media (max-width: 600px) {
  .simple-info-top {
    display: block;
    margin-bottom: 10px;
  }

  /* 调整视频和聊天容器的布局为上下排列 */
  #video-chat-container {
    flex-direction: column;
    height: auto; /* 高度自动调整 */
  }

  /* 播放器在顶部，16:9 比例 */
  #video-container {
    width: 100%;
    height: auto;
    aspect-ratio: 16 / 9; /* 确保是 16:9 比例 */
  }

  /* 聊天容器位于视频下方，填满剩余空间 */
  #chat-container {
    width: 100%;
    flex-grow: 1; /* 让聊天容器填充剩余空间 */
    display: flex;
    flex-direction: column;
  }

  /* 聊天输入框以及消息列表 */
  #chat-messages-container {
    padding: 10px;
  }

  #info {
    display: none;
  }
}
</style>