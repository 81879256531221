<template>
  <div class="ven-email-container">
    <h1>迁移到 VEN 账户</h1>
    <div class="input-container">
      <label for="venEmail">VEN 账户电子邮件地址</label>
      <input type="email" id="venEmail" v-model="venEmail" />
    </div>
    <mdui-button @click="bindVenAccount" :disabled="isSubmitting">
      开始迁移
      <!-- eslint-disable-next-line vue/no-deprecated-slot-attribute -->
      <mdui-icon slot="icon" name="mail"></mdui-icon>
    </mdui-button>

    <p v-if="successMessage" class="success-message">{{ successMessage }}</p>
    <p v-if="errorMessage" class="error-message">{{ errorMessage }}</p>

    <p>
      在迁移至你的VEN账户之后，你的密码将暂时仍然可用，直到我们彻底迁移至VEN账户，你的密码将会不可用。<br />
      现在尽可能在任何不要求密码支持VEN登录的地方使用VEN吧，也请确保你现在输入的是正确的电子邮件地址。
    </p>
  </div>
</template>

<script>
import axios from 'axios';
import { ref } from 'vue';

export default {
  name: 'BindVenPage',
  setup() {
    const venEmail = ref('');
    const isSubmitting = ref(false);
    const successMessage = ref('');
    const errorMessage = ref('');

    const bindVenAccount = async () => {
      if (!venEmail.value) {
        errorMessage.value = '电子邮件地址不能为空';
        return;
      }

      isSubmitting.value = true;
      successMessage.value = '';
      errorMessage.value = '';

      try {
        // 假设您已经有存储的JWT token
        const token = localStorage.getItem('token');
        const response = await axios.post(
          'https://1.13.182.201:5688/user/bindven',
          { venEmail: venEmail.value },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (response.data.success) {
          successMessage.value = 'VEN账户迁移成功';
        } else {
          errorMessage.value = response.data.message || '迁移失败';
        }
      } catch (error) {
        if (error.response && error.response.status === 400) {
          // 如果状态码是400，表示用户已绑定过
          errorMessage.value = '该账户已迁移到VEN，不能重复迁移';
        } else {
          errorMessage.value = '请求失败，请稍后再试';
        }
      } finally {
        isSubmitting.value = false;
      }
    };

    return {
      venEmail,
      isSubmitting,
      successMessage,
      errorMessage,
      bindVenAccount,
    };
  },
};
</script>

<style scoped>
.ven-email-container {
  padding: 20px;
  max-width: 400px;
  margin: 0 auto;
}

.input-container {
  margin-bottom: 20px;
}

input {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
}

.success-message {
  color: green;
}

.error-message {
  color: red;
}
</style>