import { createRouter, createWebHistory } from 'vue-router';
import HomePage from './views/Home/Home.vue';
import RoomPage from './views/watch/Room.vue';
import SpacePage from './views/users/Space.vue';
import NotFoundPage from './views/err/404.vue';
import AllLiveRoomsPage from './views/Home/AllLive.vue';
// import RedirectSpacePage from './views/function/RedirectSpace.vue';
import SearchPage from './views/search/Search.vue';
import SearchResults from './views/search/SearchResults.vue';
import LoginPage from './views/users/LoginPage.vue';
import LogoutPage from './views/users/Logout.vue';
import UserManagePage from './views/users/Users.vue';
import NeedLoginPage from './views/err/needlogin.vue';
import EditNamePage from './views/users/Editname.vue';
import RegisterPage from './views/users/register.vue';
import AboutPage from './views/settings/About.vue';
import DownloadPage from './views/Home/Download.vue'
import OAuthCallbackComponent from './views/users/OAuthCallbackComponent.vue';
import BindVenPage from './views/users/BindVenPage.vue';
import AnchorPortal from './views/MyAccess/AnchorPortal.vue';
import ChatWindow from './views/MyAccess/ChatWindow.vue';
import ArticlePage from './views/Home/ArticlePage.vue';
const routes = [
  {
    path: '/',
    name: 'Home',
    component: HomePage,
  },
  {
    path: '/room/:id',
    name: 'Room',
    component: RoomPage,
  },
  // {
    // path: '/space/:udid',
    // name: 'UserSpace',
    // component: SpacePage,
  // },
  {
    path: '/about',
    name: 'AboutPage',
    component: AboutPage,
  },
  {
    path: '/space/mcn/:udid',
    name: 'McnSpace',
    component: SpacePage,
  },
  {
    path: '/radio',
    name: 'Radio',
    component: SpacePage,
  },
  {
    path: '/allrooms',
    name: 'All',
    component: AllLiveRoomsPage,
    meta: { requiresAuth: true }, // 需要授权
  },
  {
    path: '/settings',
    name: 'Settings',
    component: SpacePage,
  },
  {
    path: '/err/404',
    name: 'NotFoundPage',
    component: NotFoundPage,
  },
  // {
    // path: '/@:udid',
    // name: 'RedirectSpacePage',
    // component: RedirectSpacePage,
  // },
  {
    path: '/search',
    name: 'SearchPage',
    component: SearchPage,
  },
  {
    path: '/search/:searchterm',
    name: 'SearchResults',
    component: SearchResults,
  },
  {
    path: '/err/needlogin',
    name: 'NeedLogin',
    component: NeedLoginPage, // 登录页面路由
  },
  {
    path: '/login',
    name: 'Login',
    component: LoginPage, // 登录页面路由
  },
  {
    path: '/register',
    name: 'RegisterPage',
    component: RegisterPage, // 登录页面路由
  },
  {
    path: '/my',
    name: 'UserManagePage',
    component: UserManagePage,
    meta: { requiresAuth: true }, // 需要授权
  },
  {
    path: '/my/editname',
    name: 'EditNamePage',
    component: EditNamePage,
    meta: { requiresAuth: true }, // 需要授权
  },
  {
    path: '/my/movetoven',
    name: 'BindVenPage',
    component: BindVenPage,
    meta: { requiresAuth: true }, // 需要授权
  },
  {
    path: '/:catchAll(.*)', // 捕获所有未匹配的路由
    redirect: '/err/404',
  },
  {
    path: '/my/logout',
    name: 'LogoutPage',
    component: LogoutPage,
  },
  {
    path: '/download',
    name: 'DownloadPage',
    component: DownloadPage,
  },
  {
    path: '/oauth/callback',
    name: 'OAuthCallback',
    component: OAuthCallbackComponent, // 您需要创建这个组件
  },
  {
    path: '/anchorportal',
    name: 'AnchorPortal',
    component: AnchorPortal,
    meta: { requiresAuth: true }, // 需要授权
  },
  {
    path: '/anchorportal/chat-window',
    name: 'ChatWindow',
    component: ChatWindow,
  },
  {
    path: '/article/:id',
    name: 'ArticlePage',
    component: ArticlePage,
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

// 使用 Vue Router 的钩子触发加载事件
router.beforeEach((to, from, next) => {
  const isAuthenticated = !!localStorage.getItem('token'); // 判断是否已登录
  if (to.meta.requiresAuth && !isAuthenticated) {
    next({
      path: '/err/needlogin',
      query: { redirecturi: to.fullPath }, // 将目标URL传递给needlogin页面
    });
  } else {
    next(); // 允许访问
  }
});

router.afterEach(() => {
  const app = router.app;
  if (app && app.config.globalProperties.$emitter) {
    app.config.globalProperties.$emitter.emit('loading', false);
  }
});

router.onError(() => {
  const app = router.app;
  if (app && app.config.globalProperties.$emitter) {
    app.config.globalProperties.$emitter.emit('loading', false);
  }
});

export default router;