<!-- OAuthCallbackComponent.vue -->
<template>
  <div style="padding: 20px;">
    <div style="margin-top: 10px;"></div>
    <div v-if="errorMessage">{{ errorMessage }}</div>
    <div v-else>
    <mdui-circular-progress></mdui-circular-progress>
    正在登录，请稍候...</div>
  </div>
</template>

<script>
import axios from 'axios';
import { setLoginState } from '@/auth'; // 确保所有 import 在顶层

export default {
  name: 'OAuthCallbackComponent',
  data() {
    return {
      errorMessage: '',
    };
  },
  async mounted() {
    const code = this.$route.query.code;
    
    if (!code) {
      this.errorMessage = '授权失败，没有收到授权码。';
      return;
    }

    try {
      // 将授权码发送到后端进行处理
      const response = await axios.post('https://1.13.182.201:5688/oauth2/callback', { code });

      if (response.data.success) {
        const token = response.data.token;

        // 存储 JWT 令牌
        localStorage.setItem('token', token);

        // 获取用户信息
        const userInfoResponse = await axios.get('https://1.13.182.201:5688/user/info', {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        if (userInfoResponse.data.success) {
          const userInfo = userInfoResponse.data.user;
          localStorage.setItem('avatarUrl', userInfo.avtr_url);

          // 设置登录状态
          setLoginState(true, userInfo.avtr_url);

          // 重定向到主页或其他页面
          const redirectUri = this.$route.query.redirecturi || '/';
          this.$router.push(redirectUri);
        } else {
          this.errorMessage = '无法获取用户信息，请稍后再试。';
        }
      } else {
        this.errorMessage = response.data.message;
      }
    } catch (error) {
      // 检查是否为 400 错误并设置自定义消息
      if (error.response && error.response.status === 400) {
        this.errorMessage = '授权码已过期，请重新登录。';
      } else {
        console.error('OAuth 回调过程中发生错误:', error);
        this.errorMessage = '登录失败，请稍后再试。';
      }
    }
  },
};
</script>