<template>
  <router-link :to="`/room/${room.room_id}`" class="no-decoration">
    <mdui-card class="card" href="/room/" style="width: 200px; height: 124px;">
      <div class="image-container">
        <img :src="room.image_url" :alt="`直播间图片: ${room.room_id}`" class="card-image" :style="imageStyle" />
      </div>
      <div style="position: absolute; bottom: 5px; left: 10px; display: flex; align-items: center;">
        <div class="dot" :style="{
          width: '5px',
          height: '5px',
          borderRadius: '50%',
          backgroundColor: room.status === 'on' ? 'red' : 'grey',
          marginRight: '5px'
        }"></div>
        <div class="status" style="font-size: 12px;">{{ room.status === 'on' ? '正在直播' : '未在直播' }}</div>
      </div>
    </mdui-card>
  </router-link>
</template>

<script>
export default {
  name: 'LiveRoomCard',
  props: {
    room: {
      type: Object,
      required: true,
    },
  },
  computed: {
    imageStyle() {
      const isDarkMode = localStorage.getItem('darkMode') === 'on';
      return {
        filter: isDarkMode ? 'none' : 'drop-shadow(0 4px 8px rgba(0, 0, 0, 0.4))',
        borderRadius: '5px', // 可选：为图片添加圆角
      };
    },
  },
};
</script>

<style scoped>
.card {
  text-align: center;
  color: black;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.image-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.card-image {
  max-width: 100px;
  /* 控制图片最大宽度 */
  max-height: 50px;
  /* 控制图片最大高度 */
}

.status {
  white-space: normal;
  overflow: hidden;
  display: block;
}

a {
  text-decoration: none;
  /* 移除链接的默认下划线 */
}
</style>