<template>
    <div class="editname-container">
        <h1>修改用户名</h1>
        <div class="input-container">
            <label for="newUsername">新用户名</label>
            <input type="text" id="newUsername" v-model="newUsername" />
        </div>
        <mdui-button @click="updateUsername" :disabled="isSubmitting">
            提交修改
            <!-- eslint-disable-next-line vue/no-deprecated-slot-attribute -->
            <mdui-icon slot="icon" name="edit"></mdui-icon>
        </mdui-button>

        <p v-if="successMessage" class="success-message">{{ successMessage }}</p>
        <p v-if="errorMessage" class="error-message">{{ errorMessage }}</p>

        <p>修改了名称之后，在名称修改前发布的实时聊天消息的发送者名称不会变动。</p>
    </div>
</template>

<script>
import axios from 'axios';
import { ref } from 'vue';

export default {
    name: 'EditNamePage',
    setup() {
        const newUsername = ref('');
        const isSubmitting = ref(false);
        const successMessage = ref('');
        const errorMessage = ref('');

        const updateUsername = async () => {
            if (!newUsername.value) {
                errorMessage.value = '用户名不能为空';
                return;
            }

            isSubmitting.value = true;
            successMessage.value = '';
            errorMessage.value = '';

            try {
                const token = localStorage.getItem('token');
                const response = await axios.post(
                    'https://1.13.182.201:5688/user/editname',
                    { newNickname: newUsername.value },
                    {
                        headers: {
                            Authorization: `Bearer ${token}`,
                        },
                    }
                );

                if (response.data.success) {
                    successMessage.value = '用户名修改成功';
                } else {
                    errorMessage.value = response.data.message || '修改失败';
                }
            } catch (error) {
                errorMessage.value = '请求失败，请稍后再试';
            } finally {
                isSubmitting.value = false;
            }
        };

        return {
            newUsername,
            isSubmitting,
            successMessage,
            errorMessage,
            updateUsername,
        };
    },
};
</script>

<style scoped>
.editname-container {
    padding: 20px;
    max-width: 400px;
    margin: 0 auto;
}

.input-container {
    margin-bottom: 20px;
}

input {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
}

.success-message {
    color: green;
}

.error-message {
    color: red;
}
</style>