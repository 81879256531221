<template>
  <div class="login-container">
    <div class="left-side">
      <div class="promo-content">
        <img src="@/assets/vds-livestream-small-white.png" class="logo" alt="Logo" />
        <h1 class="slogan">
          在
          <span class="scrolling-container" :style="{ width: dynamicWidth }">
            <transition-group name="scroll" tag="div" class="scrolling-text">
              <span v-if="currentPlace" :key="currentPlace" class="place-text">{{ currentPlace }}</span>
            </transition-group>
          </span>
          轻松看直播，处处都精彩
        </h1>
        <h2>欢迎回到 VDS LiveStream</h2>
        <p>体验优质的直播内容，在我们的社区，与全球观众互动。</p>
      </div>
    </div>
    <div class="right-side">
      <mdui-card class="mdui-card">
        <form @submit.prevent="handleLogin">
          <img src="@/assets/vds-livestream-small-white.png" class="logo-small" alt="Logo" />
          <h1>登录</h1>
          <div>
            <label for="username">电子邮件地址:</label>
            <mdui-text-field v-model="username" type="text" id="username" required variant="outlined"></mdui-text-field>
          </div>
          <div style="margin-top: 10px;">
            <label for="password">密码:</label>
            <mdui-text-field v-model="password" type="password" id="password" required
              variant="outlined"></mdui-text-field>
          </div>
          <mdui-button full-width type="submit" :loading="loading" style="margin-top: 10px;">登录</mdui-button>
          <div v-if="errorMessage" class="error-message">{{ errorMessage }}</div>
          <mdui-button full-width style="margin-top: 10px;" @click="loginWithVen">通过 VEN 账户登录</mdui-button>

          <p style="font-size: 10px;">提示：我们正在逐渐从VDS LiveStream账号体系迁移至VEN账号，欢迎使用登录后的工具，现在就完成绑定和今后的迁移。如果你有旧的 MyAccess
            账户，在这里登录。如果你没有直播间，就需要点击<a href="register" @click="$router.push('/register')">这里来创建新账户</a>，新账户可能也能访问当前的旧版本 MyAccess 主播门户 但是无法显示任何直播间。</p>
        </form>
      </mdui-card>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import { setLoginState } from '@/auth';

export default {
  name: 'UserLoginPage',
  data() {
    return {
      username: '',
      password: '',
      loading: false,
      errorMessage: '',
      places: [
        '家中',
        '摸鱼时',
        '甲板上',
        '地铁上',
        '咖啡馆里',
        '淋雨时',
        '等飞机时',
        '聚餐时',
        '公园里',
        '等待时',
        '游戏中',
        '出毛时',
        '车上时',
        '洗漱时',
        '编程时',
        '做饭时',
        '这条彩蛋时',
        '唠嗑时',
        '兽聚时',
        'VR时',
        '炒菜时',
        '脑子好炸时',
        '如厕时',
        '剪片时',
        '钓鱼时',
        '沙发上',
        '树下乘凉时',
        '赏月时',
        '看星星时',
        '放松时',
        '听音乐时',
        '看海时',
        '野营时',
        '登山时',
        '骑车时',
        '远足时',
      ],
      currentPlace: '',
      dynamicWidth: '3em',
    };
  },
  methods: {
    loginWithVen() {
    const clientId = 'VDS-LiveStream'; // 替换为您的客户端 ID
    const redirectUri = encodeURIComponent('https://lsnext.vds.pub/oauth/callback'); // 替换为您的重定向 URI
    const responseType = 'code';
    const scope = 'read';
    const state = 'RBbUQDgpEYTxFwHt3pmQZUNCY4ENAZKdhYds4hCKd6YT7YwWFoZssoVkcK2J24ds'; // 您可以在此生成一个随机字符串

    const authUrl = `https://vdsentnet.com/authorize?client_id=${clientId}&redirect_uri=${redirectUri}&response_type=${responseType}&scope=${scope}&state=${state}`;

    // 重定向用户到 OAuth2 提供商的授权页面
    window.location.href = authUrl;
  },
    async handleLogin() {
      this.loading = true;
      this.errorMessage = '';
      try {
        const username = document.getElementById('username').value;
        const password = document.getElementById('password').value;

        const response = await axios.post('https://1.13.182.201:5688/login', {
          username: username,
          password: password,
          appType: 'web_app',
        });

        if (response.data.success) {
          localStorage.setItem('token', response.data.token);

          const userInfoResponse = await axios.get('https://1.13.182.201:5688/user/info', {
            headers: {
              Authorization: `Bearer ${response.data.token}`,
            },
          });

          if (userInfoResponse.data.success) {
            const userInfo = userInfoResponse.data.user;
            localStorage.setItem('avatarUrl', userInfo.avtr_url);

            setLoginState(true, userInfo.avtr_url);

            const redirectUri = this.$route.query.redirecturi || '/';
            this.$router.push(redirectUri);
          } else {
            this.errorMessage = '无法获取用户信息，请稍后再试。';
          }
        } else {
          this.errorMessage = response.data.message;
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          this.errorMessage = '账户或密码错误，请检查并重试。';
        } else {
          console.error('登录失败:', error);
          this.errorMessage = '未知原因导致的登录失败，请稍后再试。';
        }
      } finally {
        this.loading = false;
      }
    },
    rotatePlaces() {
      const randomIndex = Math.floor(Math.random() * this.places.length);
      this.currentPlace = this.places[randomIndex];
      this.updateWidth();
    },
    updateWidth() {
      const tempElement = document.createElement('span');
      tempElement.style.position = 'absolute';
      tempElement.style.visibility = 'hidden';
      tempElement.style.whiteSpace = 'nowrap';
      tempElement.innerText = this.currentPlace;
      document.body.appendChild(tempElement);

      const additionalWidth = 40; // 可根据需要调整
      this.dynamicWidth = `${tempElement.offsetWidth + additionalWidth}px`;

      document.body.removeChild(tempElement);
    },
    async checkExistingToken() {
      const token = localStorage.getItem('token');
      if (token) {
        try {
          const userInfoResponse = await axios.get('https://1.13.182.201:5688/user/info', {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });

          if (userInfoResponse.data.success) {
            const userInfo = userInfoResponse.data.user;
            localStorage.setItem('avatarUrl', userInfo.avtr_url);

            setLoginState(true, userInfo.avtr_url);

            const redirectUri = this.$route.query.redirecturi || '/';
            this.$router.push(redirectUri);
          } else {
            // 如果 token 无效或过期，清除并显示登录表单
            localStorage.removeItem('token');
            localStorage.removeItem('avatarUrl');
          }
        } catch (error) {
          console.error('验证 token 失败:', error);
          localStorage.removeItem('token');
          localStorage.removeItem('avatarUrl');
        }
      }
    },
  },
  mounted() {
    this.checkExistingToken(); // 检查是否有现有的 token
    this.rotatePlaces();
    this.interval = setInterval(this.rotatePlaces, 2000); // 每2秒随机切换
  },
  beforeUnmount() {
    clearInterval(this.interval); // 组件卸载时清除定时器
  },
};
</script>

<style scoped>
/* 样式与之前相同 */
.logo {
  height: 60px;
}

.logo-small {
  height: 30px;
  display: none;
}

.login-container {
  display: flex;
  height: 100%;
  overflow: hidden;
}

.left-side {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
}

.right-side {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
}

.mdui-card {
  padding: 20px;
  max-width: 400px;
  width: 100%;
}

h1 {
  margin-bottom: 20px;
}

.promo-content {
  text-align: center;
}

.slogan {
  display: flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
  font-size: 24px;
  overflow: hidden;
  height: 33.5px;
  /* 固定高度 */
}

.scrolling-container {
  display: inline-block;
  vertical-align: middle;
  overflow: hidden;
  transition: width 0.5s ease;
  line-height: 33.5px;
  /* 确保与父元素对齐 */
  height: 33.5px;
  /* 确保容器高度固定 */
}

.scrolling-text {
  display: flex;
  color: #007bff;
  flex-direction: column;
  transition: transform 0.5s ease, opacity 0.5s ease;
  align-items: center;
  /* 使滚动的文字居中对齐 */
  height: 33.5px;
  /* 确保文字高度一致 */
}

.place-text {
  line-height: 33.5px;
  /* 确保文字垂直居中 */
  height: 33.5px;
  /* 确保每个文字块高度一致 */
  display: inline-block;
}

.scroll-enter-active,
.scroll-leave-active {
  transition: transform 0.5s ease, opacity 0.5s ease;
  position: absolute;
}

.scroll-enter-from {
  transform: translateY(100%);
  opacity: 0;
}

.scroll-enter-to {
  transform: translateY(0%);
  opacity: 1;
}

.scroll-leave-from {
  transform: translateY(0%);
  opacity: 1;
}

.scroll-leave-to {
  transform: translateY(-100%);
  opacity: 0;
}

label {
  display: block;
  margin-bottom: 5px;
}

.error-message {
  color: red;
  margin-top: 10px;
}

@media (max-width: 600px) {
  .left-side {
    display: none;
  }

  .logo-small {
    display: block;
  }
}
</style>