<template>
  <div
    class="card-wrapper"
    @mouseenter="onMouseEnter"
    @mouseleave="onMouseLeave"
    :class="{ previewing: isPreviewing }"
    :style="previewTransformStyle"
  >
    <mdui-card class="card" @click="onClick" href="#">
      <div class="image-container" v-if="!isPreviewing">
        <img
          :src="room.image_url"
          :alt="`直播间图片: ${room.room_id}`"
          class="card-image"
          :style="imageStyle"
        />
      </div>
      <div v-else class="preview-container">
        <video ref="videoPlayer" autoplay muted class="video-preview"></video>
      </div>
      <div class="status-container">
        <div
          class="dot"
          :style="{ backgroundColor: room.status === 'on' ? 'red' : 'grey' }"
        ></div>
        <div class="status">
          {{ room.status === 'on' ? '正在直播' : '未在直播' }}
        </div>
      </div>
    </mdui-card>
  </div>
</template>

<script>
import axios from 'axios';
import Hls from 'hls.js';

export default {
  name: 'LiveRoomCard',
  props: {
    room: {
      type: Object,
      required: true,
    },
    position: {
      type: String,
      default: 'middle',
    },
  },
  data() {
    return {
      isHovered: false,
      hoverTimer: null,
      isPreviewing: false,
      m3u8Url: null,
      hls: null,
    };
  },
  computed: {
    imageStyle() {
      const isDarkMode = localStorage.getItem('darkMode') === 'on';
      return {
        filter: isDarkMode
          ? 'none'
          : 'drop-shadow(0 4px 8px rgba(0, 0, 0, 0.4))',
      };
    },
    previewTransformStyle() {
    switch (this.position) {
      case 'first':
        return { transformOrigin: 'left center', zIndex: this.isPreviewing ? 10 : 1 };
      case 'last':
        return { transformOrigin: 'right center', zIndex: this.isPreviewing ? 10 : 1 };
      case 'bottom-left':
        return { transformOrigin: 'left bottom', zIndex: this.isPreviewing ? 10 : 1 };
      case 'bottom-right':
        return { transformOrigin: 'right bottom', zIndex: this.isPreviewing ? 10 : 1 };
      case 'bottom-middle':
        return { transformOrigin: 'center bottom', zIndex: this.isPreviewing ? 10 : 1 };
      default:
        return { transformOrigin: 'center center', zIndex: this.isPreviewing ? 10 : 1 };
    }
  },
  },
  methods: {
    onMouseEnter() {
      this.isHovered = true;
      this.hoverTimer = setTimeout(async () => {
        if (this.isHovered && this.room.status === 'on') {
          this.isPreviewing = true;
          await this.fetchLiveStreamUrl();
          this.$emit('preview-activated');
        }
      }, 3000);
    },
    onMouseLeave() {
      this.isHovered = false;
      clearTimeout(this.hoverTimer);
      if (this.isPreviewing) {
        this.isPreviewing = false;
        this.stopVideoPreview();
        this.$emit('preview-deactivated');
      }
    },
    onClick() {
      this.$router.push(`/room/${this.room.room_id}`);
    },
    async fetchLiveStreamUrl() {
      try {
        const response = await axios.get(
          `https://vdslivestream-servicesedge.hydun.com/preview-m3u8/${this.room.room_id}`
        );
        if (response.data.success) {
          this.m3u8Url = response.data.m3u8Url;
          this.initializeVideoPlayer();
        } else {
          console.error('Failed to fetch M3U8 URL:', response.data.message);
        }
      } catch (error) {
        console.error('Error fetching M3U8 URL:', error);
      }
    },
    initializeVideoPlayer() {
      if (this.canPlayHls()) {
        this.$refs.videoPlayer.src = this.m3u8Url;
      } else if (Hls.isSupported()) {
        if (this.hls) {
          this.hls.destroy();
        }
        this.hls = new Hls();
        this.hls.loadSource(this.m3u8Url);
        this.hls.attachMedia(this.$refs.videoPlayer);
        this.hls.on(Hls.Events.ERROR, (event, data) => {
          console.error('HLS error:', data);
        });
      } else {
        console.error('HLS is not supported in this browser.');
      }
    },
    canPlayHls() {
      const video = document.createElement('video');
      return video.canPlayType('application/vnd.apple.mpegurl') !== '';
    },
    stopVideoPreview() {
      if (this.hls) {
        this.hls.destroy();
        this.hls = null;
      }
      if (this.$refs.videoPlayer) {
        this.$refs.videoPlayer.pause();
        this.$refs.videoPlayer.removeAttribute('src');
        this.$refs.videoPlayer.load();
      }
    },
  },
  beforeUnmount() {
    this.stopVideoPreview();
  },
};
</script>

<style scoped>
.card-wrapper {
  position: relative;
  display: inline-block;
  transition: transform 0.3s ease, filter 0.3s ease;
}

.card-wrapper.previewing {
  transform: scale(1.5);
  z-index: 1000;
}

.card {
  width: 200px;
  height: 124px;
  overflow: hidden;
  position: relative;
}

.card-wrapper.previewing .card {
  width: 356px;
  height: 200px;
}

.preview-container {
  width: 100%;
  height: 100%;
}

.video-preview {
  width: 100%;
  height: 100%;
  object-fit: cover;
  pointer-events: none;
}

.image-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.card-image {
  max-width: 100px;
  max-height: 50px;
}

.status-container {
  position: absolute;
  bottom: 5px;
  left: 10px;
  display: flex;
  align-items: center;
}

.dot {
  width: 5px;
  height: 5px;
  border-radius: 50%;
  margin-right: 5px;
}

.status {
  font-size: 12px;
  color: white;
}
</style>