<template>
  <div>
    <div id="content">
      <div class="spotlight-container">
        <div class="spotlight-header">
          <h2>Spotlight</h2>
          <span class="date">{{ currentDate }}</span>
        </div>
        <div class="live-rooms-container shadow-container" ref="spotlightContainer" @wheel="handleWheel">
          <div v-if="loadingArticles" class="loading-spinner">
            <mdui-circular-progress></mdui-circular-progress>
          </div>
          <transition name="fade" mode="out-in">
            <div v-if="!loadingArticles" class="cards-container">
              <SpotlightCard
                v-for="(article) in spotlightArticles"
                :key="article.id"
                :article="article"
              />
            </div>
          </transition>
        </div>
      </div>

      <div class="section-header">
        <h2>精选</h2>
      </div>
      <div class="live-rooms-container shadow-container" ref="recommendContainer" @wheel="handleWheel">
        <div v-if="loadingRecommend" class="loading-spinner">
          <mdui-circular-progress></mdui-circular-progress>
        </div>
        <transition name="fade" mode="out-in">
          <div v-if="!loadingRecommend" class="cards-container" :class="{ darkened: isPreviewing }">
            <LiveRoomCard
              v-for="(room, index) in recommendRooms"
              :key="room.room_id"
              :room="room"
              :position="getPosition(index, recommendRooms.length)"
              @preview-activated="onPreviewActivated"
              @preview-deactivated="onPreviewDeactivated"
            />
          </div>
        </transition>
      </div>

      <div class="section-header">
        <h2>正在进行的直播</h2>
      </div>
      <div class="live-rooms-container shadow-container" ref="liveContainer" @wheel="handleWheel">
        <div v-if="loadingLive" class="loading-spinner">
          <mdui-circular-progress></mdui-circular-progress>
        </div>
        <transition name="fade" mode="out-in">
          <div v-if="!loadingLive" class="cards-container" :class="{ darkened: isPreviewing }">
            <LiveRoomCard
              v-for="(room, index) in liveRooms"
              :key="room.room_id"
              :room="room"
              :position="getPosition(index, liveRooms.length)"
              @preview-activated="onPreviewActivated"
              @preview-deactivated="onPreviewDeactivated"
            />
          </div>
        </transition>
      </div>

      <div style="margin-bottom: 75px;"></div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import LiveRoomCard from '@/components/HomeLiveCard.vue';
import SpotlightCard from '@/components/SpotlightCard.vue';

export default {
  name: 'HomePage',
  components: {
    LiveRoomCard,
    SpotlightCard,
  },
  data() {
    return {
      recommendRooms: [],
      liveRooms: [],
      spotlightArticles: [],
      loadingRecommend: true,
      loadingLive: true,
      loadingArticles: true, // 加载文章的标志
      isPreviewing: false,
      currentDate: new Date().toLocaleDateString(),
      isTouchpad: false, // 检测触摸板
    };
  },
  mounted() {
    this.checkForTouchpad();
    this.fetchRecommendRooms();
    this.fetchLiveRooms();
    this.fetchSpotlightArticles(); // 加载文章
  },
  methods: {
    checkForTouchpad() {
      this.isTouchpad = window.matchMedia('(pointer: coarse)').matches;
    },
    handleWheel(event) {
      if (this.isTouchpad) return;
      const container = event.currentTarget;
      const delta = Math.sign(event.deltaY) * 300;

      // 检查滚动容器是否可以继续滚动
      const maxScrollLeft = container.scrollWidth - container.clientWidth;

      if (delta > 0 && container.scrollLeft >= maxScrollLeft) {
        return; // 允许页面滚动
      } else if (delta < 0 && container.scrollLeft <= 0) {
        return; // 允许页面滚动
      }

      container.scrollBy({ left: delta, behavior: 'smooth' });
      event.preventDefault();
    },
    async fetchRecommendRooms() {
      try {
        const response = await axios.get('https://static.vdsentnet.com/lsapi/recommend/');
        this.recommendRooms = response.data;
      } catch (error) {
        console.error('Error fetching recommended rooms:', error);
      } finally {
        this.loadingRecommend = false;
      }
    },
    async fetchLiveRooms() {
      try {
        const response = await axios.get('https://static.vdsentnet.com/lsapi/living/');
        this.liveRooms = response.data;
      } catch (error) {
        console.error('Error fetching live rooms:', error);
      } finally {
        this.loadingLive = false;
      }
    },
    async fetchSpotlightArticles() {
      try {
        const response = await axios.get('https://vdslivestream-servicesedge.hydun.com/spotlight-articles'); // 修改为实际后端文章接口
        if (response.data.success) {
          this.spotlightArticles = response.data.data;
        } else {
          console.error('Failed to fetch articles:', response.data.message);
        }
      } catch (error) {
        console.error('Error fetching articles:', error);
      } finally {
        this.loadingArticles = false;
      }
    },
    onPreviewActivated() {
      this.isPreviewing = true;
    },
    onPreviewDeactivated() {
      this.isPreviewing = false;
    },
    getPosition(index, length) {
      if (index === 0) return 'first';
      if (index === length - 1) return 'last';
      return 'middle';
    },
  },
};
</script>

<style scoped>
.spotlight-container {
  margin-bottom: 20px;
  position: relative;
}

.spotlight-header {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 10px;
}

.spotlight-header h2 {
  color: #ff4081;
  font-size: 24px;
  text-shadow: 0 0 5px rgba(255, 64, 129, 0.7), 0 0 10px rgba(255, 64, 129, 0.5), 0 0 15px rgba(255, 64, 129, 0.3);
  transition: text-shadow 0.3s ease;
}

.date {
  font-size: 14px;
  color: gray;
  margin-top: -20px;
}

.section-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.live-rooms-container {
  display: flex;
  gap: 10px;
  overflow-x: auto;
  position: relative;
  min-height: 100px;
  height: auto;
  white-space: nowrap;
  scroll-snap-type: x mandatory;
}

.cards-container {
  display: flex;
  gap: 10px;
  overflow: visible;
  position: relative;
  transition: all 0.3s ease;
}

.cards-container>* {
  scroll-snap-align: start;
}

.loading-spinner {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100px;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.2s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.darkened .card-wrapper:not(.previewing) {
  filter: brightness(50%);
  transition: filter 0.3s ease;
}
</style>